import {b} from "../AuthForm";


export const Step: React.FunctionComponent<TStepProps> = (props) =>
  <div className={b("step", { type: props.name })}>
    {props.children}
  </div>


type TStepProps = {
  name: string;
}