import { cn } from "../../lib";


const b = cn("icon");

export const Arrow: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" className={b({}, [props.className])}>
    <path d="m6.99999 5.58514 4.79301-4.789996c.1876-.187508.4421-.292796.7073-.292703.2653.000094.5197.105562.7072.293203s.2928.442086.2927.707356-.1056.51964-.2932.70714l-5.50001 5.496c-.18753.18748-.44184.29279-.707.29279-.26517 0-.51947-.10531-.707-.29279l-5.500001-5.496c-.187641-.1875-.293109-.44187-.293203-.70714-.000093-.26527.105195-.519715.292703-.707356s.441881-.293109.707151-.293203c.26527-.000093.51971.105195.70735.292703z" fill={props.fill}/>
  </svg>

Arrow.defaultProps = {
  fill: "#000",
  width: 14,
  height: 8,
}


export const Mark: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg" className={b({}, [props.className])}>
    <path d="m5.38533 7.78973-3.57-3.85583c-.18883-.20281-.45017-.32275-.72707-.33367-.276886-.01093-.54687.08804-.751095.27534-.100738.09239-.182241.20377-.2398336.32774-.0575923.12396-.09014038.25808-.09577686.39466-.00563648.13657.01574956.27292.06293126.40121.0471812.12829.1192302.24601.2120122.34639l5.080832 5.48803 8.35447-8.74187c.0944-.09873.1684-.21511.2177-.34248.0494-.12737.0731-.26322.0699-.39978-.0033-.13655-.0335-.27112-.0888-.396003-.0553-.124878-.1348-.237608-.2338-.331734-.2011-.191272-.4699-.294992-.7475-.288432-.2775.006561-.5411.122867-.733.323432z" fill={props.fill}/>
  </svg>

Mark.defaultProps = {
  fill: "#000",
  width: 14,
  height: 11,
}


export const Recent: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 7 11" xmlns="http://www.w3.org/2000/svg" className={b({}, [props.className])}>
    <path clipRule="evenodd" d="m6.42841 9.5588c.09838.2462.09509.5215-.0094.7652-.10451.2437-.30161.4359-.54781.5344-.2462.0984-.5215.0951-.7652-.0094l-4.197003-1.8c-.552002-.236-.908997-.779-.908997-1.379v-6.17c0-.26522.105408-.519569.292908-.707108.1875-.18753.441894-.292892.707092-.292892.2652 0 .51959.105362.70709.292892.1875.187539.29291.441888.29291.707108v5.841l3.894 1.67c.2437.1045.4359.3016.53441.5478z" fill={props.fill} fillRule="evenodd"/>
  </svg>

Recent.defaultProps = {
  fill: "#000",
  width: 7,
  height: 11,
}


export const Cross: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" className={b({}, [props.className])}>
    <path clipRule="evenodd" d="m6.30328 4.88908 3.18201-3.18198c.19288-.19294.29059-.44512.2928-.698l.00009-.009106c0-.009369-.00014-.018722-.0004-.028061-.00013-.004882-.0003-.009758-.0005-.014633-.0103-.241508-.10761-.480011-.29199-.664406-.18441-.184402-.42292-.2817303-.66442-.29198419-.01419-.00061036-.02839-.0009079-.04269-.0009079l-.0091.00003814c-.25289.00228882-.50511.09991455-.69799.29285395l-3.18201 3.181976-3.18198-3.181976c-.39051-.3905255-1.023693-.3905255-1.414211 0-.3905185.390519-.3905185 1.023696 0 1.414206l3.181971 3.18198-3.181971 3.18202c-.3905185.3905-.3905185 1.0237 0 1.4142.390518.3905 1.023701.3905 1.414211 0l2.10936-2.1094 1.07262-1.0726 3.18201 3.182c.39048.3905 1.02369.3905 1.4142 0 .39048-.3905.39048-1.0237 0-1.4142z" fill={props.fill} fillRule="evenodd"/>
  </svg>

Cross.defaultProps = {
  fill: "#000",
  width: 10,
  height: 10,
}


export const Logout: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" className={b({}, [props.className])}>
    <path clipRule="evenodd" d="m18.593 7.943c.584.585.584 1.53 0 2.116l-2.883 2.891c-.39.39-1.03.39-1.42 0-.0927-.0925-.1663-.2024-.2164-.3234-.0502-.1209-.076-.2506-.076-.3816s.0258-.2607.076-.3816c.0501-.121.1237-.2309.2164-.3234.5104-.5107 1.077-.9619 1.689-1.345l.387-.242-.207-.206c-.7347.16883-1.4861.254-2.24.254h-7.921c-.26522 0-.51957-.10536-.70711-.29289-.18753-.18754-.29289-.44189-.29289-.70711s.10536-.51957.29289-.70711c.18754-.18753.44189-.29289.70711-.29289h7.921c.7539-.00003 1.5053.08517 2.24.254l.207-.206-.386-.241c-.6125-.38401-1.1794-.83623-1.69-1.348-.0927-.09251-.1663-.2024-.2164-.32338-.0502-.12097-.076-.25065-.076-.38162s.0258-.26065.076-.38162c.0501-.12098.1237-.23087.2164-.32338.39-.39 1.03-.39 1.42 0zm-7.593 5.057c-.2652 0-.5196.1054-.7071.2929s-.2929.4419-.2929.7071v1.5c0 .1326-.05268.2598-.14645.3536-.09376.0937-.22094.1464-.35355.1464h-7c-.13261 0-.25979-.0527-.35355-.1464-.09377-.0938-.14645-.221-.14645-.3536v-13c0-.13261.05268-.25979.14645-.35355.09376-.09377.22094-.14645.35355-.14645h7c.13261 0 .25979.05268.35355.14645.09377.09376.14645.22094.14645.35355v1.505c0 .26522.1054.51957.2929.70711.1875.18753.4419.29289.7071.29289s.5196-.10536.7071-.29289c.1875-.18754.2929-.44189.2929-.70711v-1.505c0-.66304-.2634-1.29893-.7322-1.767767-.4689-.468841-1.1048-.732233-1.7678-.732233h-7c-.66304 0-1.29893.263392-1.767767.732233-.468841.468837-.732233 1.104727-.732233 1.767767v13c0 .663.263392 1.2989.732233 1.7678.468837.4688 1.104727.7322 1.767767.7322h7c.663 0 1.2989-.2634 1.7678-.7322.4688-.4689.7322-1.1048.7322-1.7678v-1.5c0-.2652-.1054-.5196-.2929-.7071s-.4419-.2929-.7071-.2929z" fill={props.fill} fillRule="evenodd"/>
  </svg>

Logout.defaultProps = {
  fill: "#fff",
  width: 20,
  height: 18,
}


export const PlusFilled: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="m12 0c-6.62744 0-12 5.37257-12 12 0 6.6274 5.37256 12 12 12 6.6274 0 12-5.3726 12-12 0-6.62743-5.3726-12-12-12zm-.7071 4.29289c.1875-.18753.4419-.29289.7071-.29289s.5196.10536.7071.29289.2929.4419.2929.70711v6h6c.2652 0 .5196.1054.7071.2929s.2929.4419.2929.7071-.1054.5196-.2929.7071-.4419.2929-.7071.2929h-6v6c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929-.5196-.1054-.7071-.2929-.2929-.4419-.2929-.7071v-6h-6c-.2652 0-.51959-.1054-.70709-.2929-.18756-.1875-.29291-.4419-.29291-.7071s.10535-.5196.29291-.7071c.1875-.1875.44189-.2929.70709-.2929h6v-6c0-.26521.1054-.51958.2929-.70711z" fill={props.fill} fillRule="evenodd"/>
  </svg>

PlusFilled.defaultProps = {
  fill: "#000",
  width: 24,
  height: 24,
}


export const Star: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width={props.width} height={props.height} viewBox="0 0 31 28" xmlns="http://www.w3.org/2000/svg">
    <path d="m18.6369 10.3537v-10.3537h-6.4583v10.3537l-10.05658-3.69132-2.12202 5.76202 10.0565 3.5113-6.1815 8.463 5.53572 3.6013 5.99698-8.7331 6.6429 8.7331 4.8899-3.6913-6.0893-8.373 10.1488-3.5113-2.3988-5.76202z" fill={props.fill}/>
  </svg>

Star.defaultProps = {
  fill: "#000",
  width: 31,
  height: 28,
}


export const Back: React.FunctionComponent<TIconProps> = (props) =>
  <svg fill="none" width="33" height="30" viewBox="0 0 33 30" xmlns="http://www.w3.org/2000/svg">
    <path d="m18.1712 25.2771-8.34003-8.1332 22.05483-.0154.4034-.0003v-.4034-3.4473-.404l-.4039.0003-22.08373.0154 8.36713-8.16179.2962-.28888-.2961-.28898-2.4981-2.43843-.2819-.27515-.2819.27505-10.8312 10.56488-.00017.0001-2.50027 2.436-.29667.289.29656.2892 13.33175 12.9988.2816.2746.2818-.2743 2.5005-2.4339.2969-.289z" fill={props.fill} stroke={props.fill} strokeWidth=".807386"/>
  </svg>

Back.defaultProps = {
  fill: "#000",
  width: 33,
  height: 30,
}


type TIconProps = {
  fill?: string;
  width?: number;
  height?: number;

  className?: string;
}