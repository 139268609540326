export const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;


export function set(name: string, value: string, meta: ICookieMeta = {}) {
  document.cookie = serialize(name, value, meta);
}


export function get(name: string): string | null {
  return parse(document.cookie)[name] || null;
}


export function parse(cookie: string): Record<string, string> {
  const cookies: Record<string, string> = {};
  cookie.split(/;\s+/).forEach((cookie: string) => {
    const [key, val] = cookie.split("=", 2).map(decodeURIComponent);
    cookies[key] = val;
  });

  return cookies;
}


export function serialize(name: string, value: string, meta: ICookieMeta = {}): string {
  let cookie = [name, value].map(encodeURIComponent).join("=");
  if (meta.path) {
    cookie += ";path=" + meta.path;
  }

  if (meta.maxAge) {
    cookie += ";max-age=" + meta.maxAge;
  }

  if (meta.expires) {
    cookie += ";expires=" + meta.expires.toUTCString();
  }

  if (meta.secure) {
    cookie += ";secure";
  }

  return cookie;
}


export interface ICookieMeta {
  expires?: Date;
  maxAge?: number;
  path?: string;
  secure?: boolean;
}