import { IHTTPClient, IResponse } from "./api/types";


export const defaultClient: IHTTPClient = {
  request(url: string, options?: RequestInit): Promise<IResponse> {
    const responsePromise = fetch(url, options);
    const bodyPromise = responsePromise
      .then(response => response.text());

    return Promise.all([responsePromise, bodyPromise]).then(([response, body]) => {
      return {
        ok: response.ok,
        url: url,
        body: body,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        redirected: response.redirected,
      };
    });
  }
}

export const fakeClient: IFakeHTTPClient = {
  url: "",
  times: 0,
  options: undefined,
  response: null,

  replyWithError(error: Error) {
    fakeClient.response = error;
  },

  replyWith(status: number, response: Partial<IResponse> = {}) {
    const defaultResponse: IResponse = {
      ok: true,
      url: "",
      body: "",
      status: status,
      statusText: "",
      headers: {},
      redirected: false,
    };

    fakeClient.response = Object.assign(defaultResponse, response);
  },

  reset() {
    this.url = "";
    this.times = 0;
    this.options = undefined;
  },

  request(url: string, options?: RequestInit): Promise<IResponse> {
    let response = fakeClient.response;
    fakeClient.url = url;
    fakeClient.options = options;
    fakeClient.response = null;

    this.times += 1;

    if (response === null) {
      throw new Error("Response not mocked");
    }

    if (response instanceof Error) {
      return Promise.reject(response);
    }

    return Promise.resolve(Object.assign(response, { url }));
  }
}

interface IFakeHTTPClient extends IHTTPClient {
  url: string;
  times: number;
  options?: RequestInit;
  response: IResponse | Error | null;

  reset: VoidFunction;
  replyWith: (status: number, response?: Partial<IResponse>) => void;
  replyWithError: (error: Error) => void;
}