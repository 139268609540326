import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { cn } from "../../lib";
import { useClient } from "../RootProvider/RootProvider";
import { AskError } from "../../lib/core/errors";
import { useAuth } from "../../lib/hooks";
import { isLooksLikeEmail } from "../../lib/validations";
import { Checkbox, Spin } from "..";
import { Title } from "../Title";
import { Error } from "../Error";
import { Button, ConfirmationButton } from "../Button";
import { Input } from "../Input";
import Icon from "../Icon";


import "./ProfileForm.scss";


const b = cn("profile-form");

export const ProfileForm: React.FunctionComponent<TProfileFormProps> = (props) => {
  const auth = useAuth();
  const client = useClient();
  const navigate = useNavigate();

  const [shake, setShake] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>(auth.user?.email || "");
  const [error, setError] = React.useState<AskError | null>(null);
  const [username, setUsername] = React.useState<string>(auth.user?.username || "");
  const [keepSignedIn, setKeepSignedIn] = React.useState<boolean>(auth.user?.keepSignedIn || false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!shake) {
      return;
    }

    const t = setTimeout(() => setShake(false), 750);
    return () => clearTimeout(t);
  })

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  const handleDelete = () => {
    setSubmitting(true);

    client.user.delete()
      .then(() => navigate("/logout"))
      .catch(error => {
        setError(error);
        setSubmitting(false);
      });
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!isLooksLikeEmail(email) || username.length < 2) {
      return setShake(true);
    }

    setSubmitting(true);
    setError(null);

    client.user.update({ email, username, keepSignedIn })
      .then(auth.invalidate)
      .catch(setError)
      .then(() => setSubmitting(false))
  };

  const shouldEmailShake = !isLooksLikeEmail(email) && shake;
  const shouldLoginShake = username.length < 2 && shake;

  return (
    <div className={b({ readonly: isSubmitting }, [props.className])}>
      <div className={b("head")}>
        <Title size="large">Profile</Title>

        <Link to="/logout" className={b("logout")}>
          <Icon.Logout />
        </Link>
      </div>

      <Input
        value={email}
        shake={shouldEmailShake}
        disabled={isSubmitting}
        placeholder="Email"
        onChange={e => setEmail(e.target.value)}
      />

      <Input
        value={username}
        shake={shouldLoginShake}
        disabled={isSubmitting}
        placeholder="Username (public)"
        onChange={e => setUsername(e.target.value)}
      />

      <Checkbox disabled={isSubmitting} checked={keepSignedIn} onChange={(e) => setKeepSignedIn(e.target.checked)}>
        Keep me signed in
      </Checkbox>

      {error && (
        <Error value={error} embed />
      )}

      <div className={b("foot")}>
        <div className={b("foot-col")}>
          <Button type="submit" view="action" disabled={isSubmitting} className={b("submit")} onClick={handleSubmit}>
            Update
          </Button>
        </div>

        <div className={b("foot-col")}>
          {isSubmitting &&
            <Spin size="xs" />
          }

          {isSubmitting ||
            <ConfirmationButton className={b("delete")} confirm={<span className={b("confirm")}>Click again to confirm</span>} onClick={handleDelete} disabled={isSubmitting}>
              Delete account
            </ConfirmationButton>
          }
        </div>
      </div>
    </div>
  );
};


type TProfileFormProps = {
  className?: string;
}