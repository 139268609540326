import React from "react";

import {AskError} from "../../../lib/core/errors";
import {useAuth} from "../../../lib/hooks";
import {useClient} from "../../RootProvider";
import {useTrack, useUTMQuery} from "../../AnalyticsProvider";
import {Error} from "../../Error";
import {TSignupRequest} from "../../../lib/types";

import {TTermsState} from "../AuthForm";
import {TermsForm} from "../forms/TermsForm";
import {Step} from "../misc/Step";


export const StepTerms: React.FunctionComponent<TStepTermsProps> = (props) => {
  const auth = useAuth();
  const track = useTrack();
  const client = useClient();
  const utmQuery = useUTMQuery();

  const [error, setError] = React.useState<AskError | null>(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const handleSubmit = (terms: TTermsState) => {
    setSubmitting(true);
    setError(null);

    const request: TSignupRequest = {
      promo: utmQuery,
      email: props.email,
      isTermsAccepted: terms.isTermsAccepted,
      isCookiesAccepted: terms.isCookiesAccepted,
      keepSignedIn: terms.keepSignedIn,
    };

    client.user.create(request)
      .then(auth.authenticate)
      .then(() => track("CompleteRegistration"))
      .then(props.onComplete)
      .catch((error) => {
        setSubmitting(false)
        setError(error);
      });
  }

  return (
    <Step name="terms">
      <TermsForm title="Last step" submitting={isSubmitting} onSubmit={handleSubmit}>
        {error &&
          <Error value={error} embed />
        }
      </TermsForm>
    </Step>
  )
}


type TStepTermsProps = {
  email: string;

  onComplete: () => void;
}