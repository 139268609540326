import { useParams } from "react-router";

import { Content } from "../../components/Content/Content";
import { ModerationController } from "../../components/ModerationController";
import { Page } from "../../components/Page/Page";


export const ModerationResearchPage: React.FunctionComponent = () => {
  const params = useParams();
  if (!params.id) {
    return null; // TODO: This should never happened
  }

  return (
    <Page name="research">
      <Content className="page__content backface">
        <ModerationController id={params.id} />
      </Content>
    </Page>
  );
}