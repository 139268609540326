import React, { Fragment } from "react";

import { IPoint, IRect } from "../../../lib/geom";
import { THeatmapResearch } from "../../../lib/types";
import { AspectRatioArea } from "../../AspectRatioArea";
import { Heatmap } from "../../Heatmap/Heatmap";
import { THeatmapPoint } from "../../Heatmap/Heatmap.types";
import { SelectionArea } from "../../SelectionArea";
import { SelectionItem } from "../../SelectionItem";

import { b, Research, TResearchProps, TResearchState } from "../Research";
import { Row } from "../misc/Row";


export type TSelection = {
  id: string;
  frame: IRect;
}

export type TPoint = IPoint & {
  t: number;
}

type TStats = {
  min: number;
  max: number;
  med: number;
}

type THeatmapResearchProps = TResearchProps & {
  research: THeatmapResearch;
}

type THeatmapResearchState = TResearchState & {
  range: number;
  selections: TSelection[];
}

export class ResearchHeatmap extends Research<THeatmapResearchProps, THeatmapResearchState> {
  private readonly stats: TStats;
  private readonly points: TPoint[];

  constructor(props: THeatmapResearchProps) {
    super(props);

    const points = this.points = props.research.responses.map(response => {
      return response.data;
    });

    const times = points.map(p => p.t).sort((a, b) => a - b);
    const min = times[0];
    const max = times.slice(-1)[0];
    const med = Math.round(times[Math.floor(times.length / 2)] || 0);

    this.stats = {min, max, med};

    const selections: TSelection[] = (props.research.meta || []).map(frame => ({
      id: Math.random().toString(16),
      frame: frame,
    }));

    const range = points.reduce((a, c) => Math.max(a, c.t), 0);

    this.state = Object.assign({}, this.state, {
      range,
      selections,
    });

    this.onSelection = this.onSelection.bind(this);
    this.onSelectionClose = this.onSelectionClose.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
  }

  componentDidUpdate(_: THeatmapResearchProps, prevState: THeatmapResearchState) {
    if (this.state.selections !== prevState.selections) {
      this.updateMeta({
        value: this.state.selections.map(s => s.frame),
      });
    }
  }

  onSelection(frame: IRect) {
    this.setState({
      selections: this.state.selections.concat({
        id: Math.random().toString(16),
        frame: frame,
      }),
    });
  }

  onSelectionClose(id: string) {
    this.setState({
      selections: this.state.selections.filter(selection => {
        return selection.id !== id;
      }),
    });
  }

  onRangeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      range: Number(event.target.value),
    });
  }

  renderBody(): JSX.Element {
    return (
      <Fragment>
        {this.renderTask()}
        {this.renderStat()}
      </Fragment>
    );
  }

  renderTask(): JSX.Element {
    const {image} = this.props.research.task;

    const visiblePoints = pointsInTimeRange(this.points.filter(p => p.x >= 0 && p.y >= 0), 0, this.state.range);
    const percentOfVisiblePoints = makePointsToSelectionsMapping(visiblePoints, this.state.selections);
    const heatmapData: THeatmapPoint[] = visiblePoints.map(p => [p.x, p.y, 1]);

    return (
      <Row>
        <AspectRatioArea width={image.width} height={image.height} scale={image.scale}>
          <Heatmap max={5} image={image.url} points={heatmapData} />

          <SelectionArea onSelection={this.onSelection}>
            {this.state.selections.map(selection =>
              <SelectionItem
                key={selection.id}
                frame={selection.frame}
                value={percentOfVisiblePoints[selection.id]}
                onClose={() => this.onSelectionClose(selection.id)}
                onDelete={() => {}}
              />
            )}
          </SelectionArea>
        </AspectRatioArea>
      </Row>
    );
  }

  renderStat(): JSX.Element | null {
    if (!this.stats.med) {
      return null;
    }

    return (
      <Fragment>
        <Row>
          <div className={b("range")}>
            <div className={b("range-min")}>0s</div>
            <div className={b("range-input")}>
              <input value={this.state.range} type="range" min={0} max={this.stats.max} onChange={this.onRangeChange} />
            </div>
            <div className={b("range-max")}>{this.stats.max}<span>s</span></div>
          </div>
          <div>
            Median click time: {this.stats.med} sec
          </div>
        </Row>
      </Fragment>
    );
  }
}


export function pointsInRect(points: TPoint[], rect: IRect) {
  return points.filter(point => isPointInRect(point, rect));
}


export function isPointInRect(point: TPoint, rect: IRect): boolean {
  return point.x >= rect.x && point.x <= rect.x + rect.width &&
    point.y >= rect.y && point.y <= rect.y + rect.height;
}


function pointsInTimeRange(points: TPoint[], from: number, to: number): TPoint[] {
  return points.filter(point =>
    point.t >= from && point.t <= to);
}


export function makePointsToSelectionsMapping(points: TPoint[], selections: TSelection[]): Record<string, string> {
  const mapping: Record<string, string> = {};
  const totalNumberOfPoints = points.length || Infinity;

  selections.forEach((s: TSelection) => {
    const numberOfPointsInRect = pointsInRect(points, s.frame).length;
    const percentOfPointsInRect = numberOfPointsInRect / totalNumberOfPoints * 100;
    mapping[s.id] = Math.round(percentOfPointsInRect) + "%";
  });

  return mapping;
}
