import { b } from "./Histogram";


export const Row: React.FunctionComponent<THistogramRowProps> = (props) =>
  <div className={b("row")} style={{ width: props.value + "%" }}>
    <div className={b("label")}>
      {props.label}
    </div>

    <div className={b("stats")}>
      <div className={b("count")}>
        {props.count}
      </div>

      <div className={b("value")}>
        {props.value}%
      </div>
    </div>
  </div>


type THistogramRowProps = {
  label: string;
  count: number;
  value: number;
};