import { cn } from "../../lib/core/cn"

import { IContentProps } from "./Content.types";
import "./Content.scss";


const b = cn("content");

export const Content: React.FunctionComponent<IContentProps> = (props) =>
  <div className={b({ nogap: props.nogap }, [props.className])}>
    { props.children }
  </div>;


Content.defaultProps = {
  nogap: false,
  className: "",
};