import { ITemplate } from "./types";


const templates: ITemplate[] = [
  {
    id: 1,
    type: "question",
    soon: false,
    title: "Single Question",
    question: "What does it mean?",
    description: "What does it mean?\nWhat do you expect to see after ... ?"
  },
  {
    id: 2,
    type: "heatmap",
    soon: false,
    title: "Where to Click",
    question: "What would you do for ... ?",
    description: "What would you do for ... ?"
  },
  {
    id: 3,
    type: "side-by-side",
    soon: false,
    title: "Side by Side",
    question: "Which one is better? Why?",
    description: "Compare two designs and discover the\u00A0benefits of each version"
  },
  {
    id: -1,
    type: "question", // TODO: Fix this
    soon: true,
    title: "Test Prototype",
    question: "",
    description: "Show your figma or other prototype\nfor users and get feedback"
  }
];

export default templates;