import { Routes, Route, Navigate } from "react-router-dom";

import {
  AuthPage,
  BlogPage,
  CasePage,
  HistoryPage,
  Home,
  Logout,
  ModerationResearchPage,
  NewResearchPage,
  NotFoundPage,
  ProfilePage,
  ResearchPage,
} from "../../pages";

import * as admin from "../../pages/AdminPage";

import { WellDone } from "../WellDone/WellDone";
import { Content } from "../Content/Content";
import { CaseController } from "../CaseController";


export const AppRoutes: React.FunctionComponent = () =>
  <Routes>
    <Route path="/" element={ <Home /> } />
    <Route path="/signin" element={ <AuthPage step="signin" /> } />

    <Route path="/profile" element={ <ProfilePage />} />
    <Route path="/templates/:type" element={ <NewResearchPage /> } />

    <Route path="/researches" element={ <HistoryPage /> } />
    <Route path="/researches/:id" element={ <ResearchPage /> } />

    <Route path="/moderation/researches" element={ <Navigate to="/admin/moderation" /> } />
    <Route path="/moderation/researches/:id" element={ <ModerationResearchPage /> } />
    <Route path="/moderation/researches/:id/success" element={ <Content className="page__content"><WellDone /></Content> } />

    <Route path="/examples/:slug" element={ <BlogPage /> } />
    <Route path="/logout" element={ <Logout /> } />

    <Route path="*" element={ <NotFoundPage /> } />

    <Route path="/cases" element={ <CasePage /> }>
      <Route path=":id" element={ <CaseController /> } />
      <Route path=":id/success" element={ <WellDone /> } />
    </Route>

    <Route path="/admin" element={ <admin.Root /> }>
      <Route path="moderation" element={ <admin.Moderation /> } />
    </Route>
  </Routes>
