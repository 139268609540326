import { b } from "../Moderation";


export const SideBySidePreview: React.FunctionComponent<TSideBySidePreviewProps> = (props) =>
  <a href={props.image} className={b("preview")} target="_blank" rel="noreferrer">
    <div className={b("preview-container")}>
      <div className={b("preview-image")} style={{ backgroundImage: `url(${props.image})` }} />
    </div>
    <div className={b("preview-label")}>
      {props.label}
    </div>
  </a>

type TSideBySidePreviewProps = {
  label: string;
  image: string;
}