import React, { Fragment } from "react";

import { cn } from "../../lib";
import { IAPIClient } from "../../lib/api";
import { NotImplemented } from "../../lib/core/errors";

import { Question, Row, Submit } from "./misc";
import { TCaseProps, TCaseState } from "./Case.types";
import "./Case.scss";


export const b = cn("case");

export abstract class Case<P extends TCaseProps, S extends TCaseState> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  get client(): IAPIClient {
    return this.props.client;
  }

  get canSubmit(): boolean {
    return !this.state.isSubmitting;
  }

  get requiredActionFromUser(): string {
    return "";
  }

  serialize(): any {
    throw new NotImplemented("#serialize() does not implemented");
  }

  onSubmit() {
    if (!this.canSubmit || this.state.isSubmitting) {
      return;
    }

    this.setState({
      isSubmitting: true,
    });

    this.client.task.submit(this.props.id, this.serialize())
      .then(this.props.onComplete)
      .catch(error => this.setState({
        isSubmitting: false,
        error: error,
      }));
  }

  renderHead(): JSX.Element {
    return (
      <Row centered>
        <Question action={ this.requiredActionFromUser }>{ this.props.data.question }</Question>
      </Row>
    );
  }

  renderBody(): JSX.Element {
    return <Fragment />
  }

  renderFoot(): JSX.Element {
    return (
      <Row centered>
        <Submit disabled={!this.canSubmit} onClick={this.onSubmit} />
      </Row>
    );
  }

  render() {
    return (
      <div className={b()}>
        { this.renderHead() }
        { this.renderBody() }
        { this.renderFoot() }
      </div>
    );
  }
}