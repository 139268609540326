import { cn } from "../../lib";
import Icon from "../Icon";

import "./TagList.scss";


const b = cn("tag-list");

export const TagList: React.FunctionComponent<TTagListProps> = (props) => {
  const items = props.items || [];
  const value = props.value || [];

  const handleChange: TTagItemChangeCallback = (tag, active) =>
    props.onChange && props.onChange(
      active ? value.concat(tag) : value.filter(t => t !== tag)
    );

  return (
    <ul className={b()}>
      {items.map(tag =>
        <TagItem key={tag} value={tag} active={value.includes(tag)} onChange={handleChange} />
      )}
    </ul>
  );
}

export const TagItem: React.FunctionComponent<TTagItemProps> = (props) => {
  const { value, active } = props;

  const handleClick = () =>
    props.onChange(value, !active);

  return (
    <li className={b("item", { active })} onClick={handleClick}>
      { active ? <Icon.Mark /> : value }
    </li>
  );
}


type TTagListProps = {
  items: string[];
  value: string[];

  onChange: TTagListChangeCallback;
}

type TTagItemProps = {
  value: string;
  active: boolean;

  onChange: TTagItemChangeCallback;
}

type TTagListChangeCallback = (tags: string[]) => void;
type TTagItemChangeCallback = (tag: string, active: boolean) => void;