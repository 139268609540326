import React, { Fragment } from "react";

import { cn } from "../../lib";
import { TPublicResearch } from "../../lib/types";
import { Track } from "../AnalyticsProvider";
import { Title } from "../Title";
import { Author, Row } from "./misc";

import "./Research.scss";


export const b = cn("rresearch");

export abstract class Research<P extends TResearchProps, S extends TResearchState> extends React.Component<P, S> {
  updateMeta(value: any) {
    this.props.onMetaUpdated(value);
  }

  renderHead(): JSX.Element {
    return (
      <Fragment>
        <Title size="large">
          Task
        </Title>

        <Title size="small" className={b("subtitle")}>
          {this.props.research.task.question}
        </Title>
      </Fragment>
    );
  }

  renderBody(): JSX.Element {
    return <Fragment />;
  }

  renderFoot(): JSX.Element | null {
    if (!this.props.credits) {
      return null;
    }

    const {id, username} = this.props.research;

    return (
      <Row className={b("author")}>
        <Author id={id} username={username} />
      </Row>
    );
  }

  renderContent() {
    const {research} = this.props;
    if (research.state === "deleted") {
      return (
        <div className={b("wait")}>
          Sorry, but the author deleted his account<br />with this research
        </div>
      );
    }

    if (research.state === "failed") {
      return (
        <div className={b("wait")}>
          An error has occurred. Please create a new research.
        </div>
      );
    }

    if (research.state !== "completed") {
      return (
        <div className={b("wait")}>
          <h3>The research has been started</h3>
          <p>You will be notified of the completion by email. <br/>Estimated time: 5 minutes.</p>
        </div>
      );
    }

    return (
      <Fragment>
        { this.renderHead() }
        { this.renderBody() }
        { this.renderFoot() }

        <Track goal="page-view" />
      </Fragment>
    )
  }

  render() {
    return (
      <div className={b()}>
        {this.renderContent()}
      </div>
    );
  }
}


export type TResearchProps = {
  research: TPublicResearch;
  credits: boolean;

  onMetaUpdated: (meta: any) => void;
}

export type TResearchState = {

}