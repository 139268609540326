import { Fragment } from "react";

import { strings } from "../../../lib";
import { TQuestionModerationResearch } from "../../../lib/types";
import { AspectRatioArea } from "../../AspectRatioArea";
import { Button } from "../../Button";

import { b, Moderation, TModerationProps, TModerationState } from "../Moderation";
import { Row } from "../misc";


type TQuestionProps = TModerationProps & {
  research: TQuestionModerationResearch;
}

type TQuestionState = TModerationState & {
  badIds: Set<number>;
};

type TLocalResponse = {
  id: number;
  value: string;
  approved: boolean | void;
  normalized: string;
};

export class Question extends Moderation<TQuestionProps, TQuestionState> {
  private readonly responses: TLocalResponse[];
  private readonly responseById: Map<number, TLocalResponse>;

  constructor(props: TQuestionProps) {
    super(props);

    const responses = this.responses = props.research.responses.map((response) => {
      return {
        id: response.id,
        value: response.data.text,
        approved: response.approved,
        normalized: strings.normalize(response.data.text),
      }
    });

    if (this.props.research.state !== "completed") {
      responses.forEach(response => {
        response.approved = undefined;
      });
    }

    this.responseById = responses.reduce((map, response) => {
      return map.set(response.id, response)
    }, new Map());

    const badIds = responses.filter(r => r.approved === false).map(r => r.id);

    this.state = Object.assign({}, this.state, {
      badIds: new Set(badIds),
    });

    this.onAnswerClick = this.onAnswerClick.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  onAnswerClick(id: number) {
    const response = this.responseById.get(id);
    if (!response) {
      return;
    }

    const nextState = new Set(this.state.badIds);

    const isExist = nextState.has(id);
    Array.from(this.responseById.keys())
      .filter(id => this.responseById.get(id)!.normalized === response.normalized)
      .forEach(id => isExist ? nextState.delete(id) : nextState.add(id));

    this.setState({
      badIds: nextState,
    });
  }

  onSubmitClick() {
    this.props.onSubmit(Array.from(this.state.badIds));
  }

  renderTask(): JSX.Element | null {
    const image = this.props.research.task.image;
    if (!image) {
      return null;
    }

    return (
      <Row>
        <AspectRatioArea width={image.width} height={image.height} scale={image.scale}>
          <img src={image.url} style={{width: "100%", height: "100%"}} alt="" />
        </AspectRatioArea>
      </Row>
    );
  }

  renderResponses(): JSX.Element {
    const {badIds} = this.state;
    return (
      <Row centered>
        <ul className={b("answers-list", { selectable: true })}>
          {this.responses.map(({id, value}) =>
            <li key={id}  className={b("answers-item", {bad: badIds.has(id)})} onClick={() => this.onAnswerClick(id)}>
              <span className={b("answers-text")}>
                {value}
              </span>
            </li>
          )}
        </ul>
      </Row>
    );
  }

  renderBody(): JSX.Element {
    return (
      <Fragment>
        {this.renderTask()}
        {this.renderResponses()}
      </Fragment>
    )
  }

  renderFoot(): JSX.Element {
    return (
      <Row centered className={b("foot")}>
        <Button type="submit" className={b("submit")} disabled={!this.canSubmit} onClick={this.onSubmitClick}>
          Submit
        </Button>
      </Row>
    );
  }
}