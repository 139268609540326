import { Fragment } from "react";

import { strings } from "../../../lib";
import { AspectRatioArea } from "../../AspectRatioArea";
import { InputValidationError } from "../../../lib/core/errors";
import { Error } from "../../Error";
import { Textarea } from "../../Textarea";

import { b, Case } from "../Case";
import { Row } from "../misc";
import { TCaseQuestionProps, TCaseQuestionState } from "../Case.types";


export class QuestionCase extends Case<TCaseQuestionProps, TCaseQuestionState> {
  constructor(props: TCaseQuestionProps) {
    super(props);
    this.state = Object.assign({}, this.state, {
      text: "",
    });

    this.onTextChange = this.onTextChange.bind(this);
  }

  get isValid(): boolean {
    const letters = this.state.text.match(/./ug) || [];
    return letters.every(char => {
      return !strings.isLetter(char) || strings.isEnglishLetter(char);
    });
  }

  get canSubmit(): boolean {
    return this.state.text.length > 0 && this.isValid;
  }

  serialize() {
    return {
      text: this.state.text,
    };
  }

  onTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      text: event.target.value,
    });

  }

  renderBody(): JSX.Element {
    const image = this.props.data.image;

    return (
      <Fragment>
        {image &&
          <Row wide>
            <AspectRatioArea width={image.width} height={image.height} scale={image.scale}>
              <img src={image.url} style={{width: "100%", height: "100%"}} alt="" />
            </AspectRatioArea>
          </Row>
        }

        <Row centered>
          <Textarea
            className={b("textarea")}
            placeholder="Your answer..."
            value={this.state.text}
            onChange={this.onTextChange}
          />
        </Row>

        {this.isValid ||
          <Row centered>
            <Error value={new InputValidationError("Only answers in English are allowed")} embed />
          </Row>
        }
      </Fragment>
    )
  }
}