import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../../lib/hooks";
import { Page } from "../../components/Page";
import { Content } from "../../components/Content/Content";


export const Root: React.FunctionComponent = (props) => {
  const auth = useAuth();
  if (auth.user?.role !== "admin") {
    return <Navigate to="/" />;
  }

  return (
    <Page name="admin">
      <Content nogap className="page__content">
        <Outlet />
      </Content>
    </Page>
  );
}