import React from "react";

import { createClient, IAPIClient } from "../../lib/api";
import { defaultClient } from "../../lib/http";

import {
  TRootContext,
  TRootProviderProps,
} from "./RootProvider.types";


export const RootContext = React.createContext<TRootContext>({} as TRootContext);

export const RootProvider: React.FunctionComponent<TRootProviderProps> = (props) => {
  const context = {
    client: createClient("/api", defaultClient),
  };

  return (
    <RootContext.Provider value={ context }>
      { props.children }
    </RootContext.Provider>
  );
};


export function useClient(): IAPIClient {
  return React.useContext(RootContext).client;
}
