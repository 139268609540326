import { useLocation } from "react-router";

import { cn } from "../../lib/core/cn";

import "./WellDone.scss";


const block = cn("well-done");

export const WellDone: React.FunctionComponent = (props) => {
  const state = useLocation().state as { token?: string };
  const token = state["token"] || "";

  return (
    <div className={ block() }>
      <h1 className={ block("title") }>Thank you!</h1>
      {Boolean(token) &&
        <p className={ block("message") }>Complete code: <br />{ token }</p>
      }
    </div>
  );
}
