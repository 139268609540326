import { cn } from "../../lib";
import { Link } from "../Link";
import Icon from "../Icon";

import "./Logo.scss";


const b = cn("logo");

export const Logo: React.FunctionComponent<TLogoProps> = (props) => {
  const hasUrl = Boolean(props.url);
  const content = props.type === "main"
    ? <Icon.Star />
    : <Icon.Back />;

  return (
    <div className={b({}, [props.className])}>
      {hasUrl &&
        <Link to={props.url!}>
          {content}
        </Link>
      }
      {hasUrl || content }
    </div>
  )
};

Logo.defaultProps = {
  url: "",
  type: "main",
  className: "",
}

type TLogoProps = {
  url?: string;
  type?: "main" | "back";
  className?: string;
}