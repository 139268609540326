import React from "react";

import {
  useAuth,
  useCookie,
  useDidMount,
  useSigninCode,
} from "../../lib/hooks";

import { IUserInfo } from "../../lib/types";
import { COOKIE_MAX_AGE } from "../../lib/cookie";
import { Spin } from "../Spin/Spin";

import { IAuthContext } from "./AuthProvider.types";
import { useClient } from "../RootProvider/RootProvider";


export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FunctionComponent = (props) => {
  const code = useSigninCode();
  const client = useClient();

  const [user, setUser] = React.useState<IUserInfo | null>(null);
  const [token, setTokenCookie] = useCookie("token");
  const [loading, setLoading] = React.useState<boolean>(true);

  useDidMount(() => {
    const shouldAuthenticate = (token || code) && !user;
    if (!shouldAuthenticate) {
      return setLoading(false);
    }

    setLoading(true);
    (code ? signin(code) : authenticate(token!))
      .catch(console.error)
      .then(() => setLoading(false));
  });

  const logout = () => {
    setTokenCookie("", {
      path: "/",
      maxAge: -1,
    });

    window.location.href = "/";
  };

  const signin = (code: string): Promise<boolean> =>
    client.user.submitCode(code).then(authenticate);

  const authenticate = (token: string): Promise<boolean> => {
    client.setToken(token);
    return client.user.fetch().then(userData => {
      setUser(userData);

      const cookieLifeTime = userData.keepSignedIn ? COOKIE_MAX_AGE : 0;
      setTokenCookie(token, {
        path: "/",
        maxAge: cookieLifeTime,
      });

      return true;
    });
  };

  const invalidate = (): Promise<boolean> =>
    token ? authenticate(token) : Promise.resolve(false);

  const context = {
    user,
    logout,
    invalidate,
    authenticate,
  };

  return (
    <AuthContext.Provider value={ context }>
      { loading ? <Spin global={true} size="xl" /> : props.children }
    </AuthContext.Provider>
  );
};


export function useIsAuthenticated(): boolean {
  return Boolean(useAuth().user);
}
