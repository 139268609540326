import { AspectRatioArea } from "../../AspectRatioArea";
import { ClickableArea } from "../../ClickableArea";
import { IPoint, Point } from "../../../lib/geom";
import { isTruthy } from "../../../lib/validations";
import { AskError } from "../../../lib/core/errors";

import { Case } from "../Case";
import { Row } from "../misc";
import { TCaseHeatmapProps, TCaseHeatmapState } from "../Case.types";


export class HeatmapCase extends Case<TCaseHeatmapProps, TCaseHeatmapState> {
  constructor(props: TCaseHeatmapProps) {
    super(props);
    this.state = Object.assign({}, this.state, {
      point: null,
      mountedAt: 0,
      clickedAt: 0,
    });

    this.onImageClick = this.onImageClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      mountedAt: Date.now(),
    });
  }

  get requiredActionFromUser(): string {
    return "Click on the image";
  }

  get canSubmit(): boolean {
    return this.state.clickedAt > 0 && isTruthy(this.state.point);
  }

  serialize() {
    const { point, mountedAt, clickedAt } = this.state;
    if (!point || !clickedAt) {
      return this.setState({
        error: new AskError("Trying to serialize case data before user action"),
      });
    }

    return {
      x: point.x,
      y: point.y,
      t: Math.round((clickedAt - mountedAt) / 1000),
    }
  }

  onImageClick(point: IPoint) {
    this.setState({
      point: new Point(point),
      clickedAt: this.state.clickedAt || Date.now(),
    });
  }

  renderBody(): JSX.Element {
    const image = this.props.data.image;
    if (!image) {
      return <></>;
    }

    const points = [];
    if (this.state.point) {
      points.push(this.state.point);
    }

    return (
      <Row>
        <AspectRatioArea width={image.width} height={image.height} scale={image.scale}>
          <img src={image.url} style={{width: "100%", height: "100%"}} alt="" />

          <ClickableArea points={points} onClick={this.onImageClick} />
        </AspectRatioArea>
      </Row>
    )
  }
}