import { Link } from "react-router-dom";

import { Title } from "../Title";
import { ITemplate } from "../../lib/types";
import { cn } from "../../lib/core/cn"

import { ITemplateListProps, IDescriptionProps } from "./TemplateList.types";
import "./TemplateList.scss";


const b = cn("template-list");

export const TemplateList: React.FunctionComponent<ITemplateListProps> = (props) => {
  return (
    <div className={b({}, ["backface", props.className])}>
      {props.items.map(props =>
        <Item {...props} key={props.title} />
      )}
    </div>
  );
}

TemplateList.defaultProps = {
  className: "",
};

const Item: React.FunctionComponent<ITemplate> = (props) => {
  const { soon, title } = props;

  const children = (
    <>
      <Title size="large" className={b("title")}>{ title }</Title>
      {props.soon &&
        <div className={b("soon")}>(COMMING SOON)</div>
      }
      <Description text={ props.description } />
    </>
  );

  const url = `/templates/${props.type}`;

  return soon
    ? <span className={b("item", { soon })}>{ children }</span>
    : <Link to={url} className={b("item")}>{ children }</Link>;
}

Item.defaultProps = {
  soon: false,
};

const Description: React.FunctionComponent<IDescriptionProps> = ({ text }) => {
  return (
    <div className={b("description")}>
      { text }
    </div>
  )
}