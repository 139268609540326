import React, { Fragment } from "react";

import { cn, strings } from "../../lib";
import { TResearchType } from "../../lib/types";
import { Error } from "../Error";
import {Button} from "../Button";
import {Title} from "../Title";

import "./NewResearchForm.scss";


export const b = cn("new-research-form");

export abstract class NewResearchForm<P extends TNewResearchFormProps, S extends TNewResearchFormState> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
  }

  get title(): string {
    return this.props.title;
  }

  get canSubmit(): boolean {
    return !this.props.submitting;
  }

  get isQuestionValid(): boolean {
    const { question } = this.state;
    return question.length > 0 && isValidText(question);
  }

  validate(): boolean {
    return false;
  }

  onSubmit() {
    if (!this.validate()) {
      return;
    }
  }

  onQuestionChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      question: event.target.value,
    });
  }

  renderTitle(): JSX.Element {
    return (
      <Title size="large">{this.title}</Title>
    );
  }

  renderForm(): JSX.Element {
    return <Fragment />;
  }

  renderSubmit(): JSX.Element {
    return (
      <Fragment>
        {this.props.error &&
          <div className={b("row")}>
            <Error value={this.props.error} embed />
          </div>
        }
        <div className={b("row")}>
          <Button type="submit" view="action" className={b("submit")} disabled={!this.canSubmit} onClick={this.onSubmit}>
            RUN
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className={b({}, ["backface"])}>
        {this.renderTitle()}
        {this.renderForm()}
        {this.renderSubmit()}
      </div>
    );
  }
}


export type TNewResearchFormProps = {
  type: TResearchType;
  title: string;
  placeholder: string;

  error: Error | null;
  submitting: boolean;
}

export type TNewResearchFormState = {
  question: string;
}

function isValidText(value: string) {
  const letters = value.match(/./ug) || [];
  return letters.every(char =>
    !strings.isLetter(char) || strings.isEnglishLetter(char));
}