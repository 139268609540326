import { cn } from "../../lib"

import "./Title.scss";


const b = cn("title");

export const Title: React.FunctionComponent<TTitleProps> = ({ size, children, className }) => {
  let Tag = "h1" as keyof JSX.IntrinsicElements;
  if (size === "medium") {
    Tag = "h2";
  }

  if (size === "small") {
    Tag = "h3";
  }

  return (
    <Tag className={b({ size }, [className])}>{ children }</Tag>
  )
}


type TTitleProps = {
  size: "large" | "medium" | "small";
  className?: string;
}