import React from "react";

import { cn } from "../../lib";

import "./Input.scss";


const b = cn("input");

export const Input: React.FunctionComponent<TInputProps> = ({ view, shake, className, ...props }) =>
  <input className={b({ view }, [className, shake ? "shake" : ""])} {...props} />;


Input.defaultProps = {
  type: "text",
  view: "default",
  shake: false,
}


type TInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onSubmit"> & {
  view?: "default" | "clear" | "round";
  shake?: boolean;
}