import React from "react";

import { cn } from "../../lib";
import { IRect, Rect } from "../../lib/geom";
import { useRect } from "../../lib/hooks";

import { TAspectRatioAreaContext, TAspectRatioAreaProps } from "./AspectRatioArea.types";
import "./AspectRatioArea.scss";


const b = cn("aspect-ratio-area");

export const AspectRatioArea: React.FunctionComponent<TAspectRatioAreaProps> = (props) => {
  const node = React.useRef<HTMLDivElement>(null);
  const frame = useRect(node);

  const scale = props.scale || 1;
  const width = props.width / scale;
  const height = props.height / scale;

  const style = {
    maxWidth: width,
    maxHeight: height,
    aspectRatio: `${width} / ${height}`,
  };

  const context = {
    frame: frame || Rect.zero(),
    bounds: frame || Rect.zero(),
  };

  const orientation = width > height ? "landscape" : "portrait";

  return (
    <div className={b({ orientation })}>
      <div ref={node} className={b("content")} style={style}>
        <FrameContext.Provider value={ context }>
          { props.children }
        </FrameContext.Provider>
      </div>
    </div>
  );
}


export const FrameContext = React.createContext<TAspectRatioAreaContext>({
  frame: Rect.zero(),
  bounds: Rect.zero(),
});


export function useFrame(): IRect {
  return React.useContext(FrameContext).frame;
}