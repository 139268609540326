import React from "react";

import {
  IThemeContext,
  IThemeProviderProps,
  TTheme
} from ".";


export const ThemeContext = React.createContext<IThemeContext>({
  theme: "default",
});

export const ThemeProvider: React.FunctionComponent<IThemeProviderProps> = (props) => {
  const context = {
    theme: props.theme,
  };

  return (
    <ThemeContext.Provider value={ context }>
      { props.children }
    </ThemeContext.Provider>
  );
};

export function useTheme(): TTheme {
  return React.useContext(ThemeContext).theme;
}