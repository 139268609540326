import React from "react";
import {useNavigate} from "react-router-dom";

import {AuthForm} from "../../components/AuthForm";
import {useIsAuthenticated} from "../../components/AuthProvider";
import {Example} from "../../components/Example/Example";
import {Page} from "../../components/Page";

import "./BlogPage.scss";


export const BlogPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const [canSignup, setCanSignup] = React.useState<boolean>(!isAuthenticated);

  const handleComplete = () => {
    setCanSignup(false);
    navigate("/");
  };

  return (
    <Page name="blog">
      <Example />

      {canSignup && (
        <AuthForm mode="signup" onComplete={handleComplete} />
      )}
    </Page>
  );
}