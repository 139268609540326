import { cn } from "../../lib"

import "./Menu.scss";


const b = cn("menu");

const Menu: React.FunctionComponent<TMenuProps> = (props) =>
  <ul className={b({ layout: props.layout }, [props.className])}>
    {props.children}
  </ul>


export const Item: React.FunctionComponent<TItemProps> = (props) =>
  <li className={b("item", {}, [props.className])}>
    {props.children}
  </li>


type TMenuProps = {
  layout?: "vertical" | "horizontal" | "auto";
  className?: string;
}

type TItemProps = {
  className?: string;
}

export default Object.assign(Menu, { Item });