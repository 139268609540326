import { Link as RouterLink } from "react-router-dom";

import { cn } from "../../lib";


const b = cn("link");

export const Link: React.FunctionComponent<TLinkProps> = (props) => {
  const isLocalLink = props.to.startsWith("/") && !props.to.startsWith("//");
  const isAjaxLink = isLocalLink && !props.newTab;

  const className = b({}, [props.className]);

  if (isAjaxLink) {
    return (
      <RouterLink to={props.to} className={className}>
        { props.children }
      </RouterLink>
    );
  }

  const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    className,
  };

  if (props.newTab) {
    linkProps.target = "_blank";
    linkProps.rel = "noreferrer";
  }

  return (
    <a href={props.to} {...linkProps}>
      { props.children }
    </a>
  );
}

type TLinkProps = {
  to: string;
  newTab?: boolean;
  className?: string;
}