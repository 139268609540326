import { Fragment } from "react";

import {AspectRatioArea} from "../../AspectRatioArea";
import {Histogram} from "../../Histogram";
import {Title} from "../../Title";
import {TagList} from "../../TagList";
import {strings} from "../../../lib";
import {TQuestionResearch} from "../../../lib/types";

import {Research} from "../Research";
import {b, TResearchProps, TResearchState} from "../Research";
import {Row} from "../misc";


type TLocalResponse = {
  id: number;
  value: string;
  normalized: string;
};

export type TQuestionResearchProps = TResearchProps & {
  research: TQuestionResearch;
}

export type TQuestionResearchState = TResearchState & {
  tagsSelected: Record<string, string[]>;
}

export class ResearchQuestion extends Research<TQuestionResearchProps, TQuestionResearchState> {
  private readonly tags: string[] = ["a", "b", "c"];
  private readonly responses: TLocalResponse[];
  private readonly responseById: Map<number, TLocalResponse>;

  constructor(props: TQuestionResearchProps) {
    super(props);

    this.state = Object.assign({}, this.state, {
      tagsSelected: props.research.meta || {},
    });

    this.onTagsChange = this.onTagsChange.bind(this);

    const responses = this.responses = props.research.responses.map((response) => {
      return {
        id: response.id,
        value: response.data.text,
        normalized: strings.normalize(response.data.text),
      }
    });

    this.responseById = responses.reduce((map, response) => {
      return map.set(response.id, response)
    }, new Map());
  }

  /**
   * Handles clicks on response tags.
   *
   * @param id Id of the response
   * @param tags Selected tags for the response
   */
  onTagsChange(id: number, tags: string[]) {
    const response = this.responseById.get(id);
    if (!response) {
      return;
    }

    const nextState = Object.assign({}, this.state.tagsSelected);
    Array.from(this.responseById.keys())
      .filter(id => this.responseById.get(id)!.normalized === response.normalized)
      .forEach(id => nextState[id] = tags);

    this.setState({
      tagsSelected: nextState,
    });

    const meta = {
      value: nextState,
    };

    this.updateMeta(meta);
  }

  renderBody(): JSX.Element {
    return (
      <Fragment>
        {this.renderTask()}
        {this.renderResponses()}
        {this.renderResults()}
      </Fragment>
    );
  }

  renderTask(): JSX.Element | null {
    const image = this.props.research.task.image;
    if (!image) {
      return null;
    }

    return (
      <Row>
        <AspectRatioArea width={image.width} height={image.height} scale={image.scale}>
          <img src={image.url} style={{width: "100%", height: "100%"}} alt="" />
        </AspectRatioArea>
      </Row>
    );
  }

  renderResponses(): JSX.Element {
    return (
      <Row>
        <div className={b("titles")}>
          <Title size="small">Responses</Title>
          <Title size="small">Tags</Title>
        </div>

        <ul className={b("answers-list")}>
          {this.responses.map(response =>
            <li key={response.id}  className={b("answers-item")}>
              <span className={b("answers-text")}>
                {response.value}
              </span>

              <TagList items={this.tags} value={this.state.tagsSelected[response.id]}
                onChange={tags => this.onTagsChange(response.id, tags)}
              />
            </li>
          )}
        </ul>
      </Row>
    );
  }

  /**
   * Renders a row with a result histogram.
   */
  renderResults(): JSX.Element {
    const {tagsSelected} = this.state;

    // TODO: Use memo or somethings
    const stats = this.tags.map(tag => ({
      label: tag,
      count: Object.keys(tagsSelected).filter(id => tagsSelected[id].includes(tag)).length,
    }));

    return (
      <Row className={b("result")}>
        <Title size="small">Task result</Title>
        <Histogram total={this.responses.length} items={stats} />
      </Row>
    );
  }
}