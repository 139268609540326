import { useRef, useState, useEffect } from "react";

import { cn } from "../../lib/core/cn";
import { Spin } from "..";
import { TImage } from "../../lib/types";
import Icon from "../Icon";

import "./ImageUploader.scss";


const b = cn("image-uploader");

export const ImageUploader: React.FunctionComponent<TImageUploaderProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isDrag, setDrag] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageMeta, setImageMeta] = useState<TImage | null>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const [viewOriginal, setViewOriginal] = useState<boolean>(false);

  useEffect(() => {
    if (imageFile && imageMeta) {
      props.onFile(imageFile, imageMeta)
    }
  }, [imageFile, imageMeta]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEmpty = !imageFile;
  const isLoading = loading;
  const hasImage = Boolean(imageFile) && Boolean(imageMeta) && !loading;

  const handleFiles = (files: FileList) => {
    const image = files[0];
    if (image && /^image\//.test(image.type)) {
      setLoading(true);
      loadImageFromFileObject(image)
        .then((meta) => {
          if (!meta) return;

          setImageFile(image);
          setImageMeta(meta);
        })
        .catch(console.error)
        .then(() => setLoading(false));
    }
  }

  const handleScale = (scale: number) => {
    if (imageMeta) {
      const nextState = Object.assign({}, imageMeta, { scale });
      setImageMeta(nextState);
    }
  }

  const handleClick = () =>
    fileInputRef.current && fileInputRef.current.click();

  const handleDragEnter = (event: React.SyntheticEvent) => {
    setDrag(true);
    event.preventDefault();
  }

  const handleDragLeave = (event: React.SyntheticEvent) => {
    setDrag(false);
    event.preventDefault();
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setDrag(false);
    handleFiles(event.dataTransfer.files);

    event.preventDefault();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.files) {
      handleFiles(fileInput.files);
    }
  };

  const handlers: React.HTMLAttributes<HTMLDivElement> = {
    onDrop: handleDrop,
    onClick: handleClick,
    onDragOver: handleDragEnter,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
  };

  const scales = [1, 2, 3].map(x => ({
    value: x,
    selected: imageMeta !== null && x === imageMeta.scale,
  }));

  const mods = {
    "highlighted": isDrag || hasImage,
    "with-preview": imageMeta !== null,
    "view-original": viewOriginal,
  };

  return (
    <div className={b(mods, [props.className])} {...handlers}>
      {isEmpty && (
        <div className={b("placeholder")}>
          {props.label ?
            <span className={b("label")}>{props.label}</span>
            : "Attach an Image"}

          {Boolean(props.label) && <PlusSign />}
        </div>
      )}

      {isLoading && (
        <Spin size="l" global />
      )}

      {hasImage && imageMeta !== null && (
        <>
          <div className={b("zoom")} onMouseEnter={() => setViewOriginal(true)} onMouseLeave={() => setViewOriginal(false)}>
            <div className={b("zoom-items")} onClick={(e) => e.stopPropagation()}>
              {scales.map(({value, selected}) =>
                <div key={value} className={b("zoom-item", { selected })} onClick={() => handleScale(value)}>x{value}</div>
              )}
            </div>
          </div>

          <div className={b("preview")}>
            <img
              src={imageMeta.url}
              className={b("preview-image")}
              width={imageMeta.width / imageMeta.scale}
              height={imageMeta.height / imageMeta.scale}
              alt=""
            />
          </div>
        </>
      )}

      <div className={b("system")}>
        <input type="file" ref={fileInputRef} accept="image/*" onChange={handleFileChange} />
      </div>
    </div>
  );
}

ImageUploader.defaultProps = {
  className: "",
};


const PlusSign: React.FunctionComponent = () =>
  <span className={b("plus")}>
    <Icon.PlusFilled />
  </span>


function loadImageFromFileObject(imageFile: File): Promise<TImage | null> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(imageFile);
    img.src = url;

    img.onerror = () => resolve(null);

    img.onload = () => resolve({
      url: url,
      scale: 1,
      width: img.naturalWidth,
      height: img.naturalHeight,
    });
  });
}


type TImageUploaderProps = {
  label?: string;
  className?: string;
  onFile: (file: File, meta: TImage) => void;
}