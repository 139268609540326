import { cn } from "../../lib";
import { AskError } from "../../lib/core/errors";

import { TErrorProps } from "./Error.types";
import "./Error.scss";


const b = cn("error");

export const Error: React.FunctionComponent<TErrorProps> = (props) => {
  const error = props.value;
  const embed = props.embed || false;

  let title = "Oops!";
  let message = "Something went wrong";

  if (error instanceof AskError) {
    if (error.title) {
      title = error.title;
    }

    if (error.userFriendly) {
      message = error.message;
    }
  }

  return (
    <div className={b({ embed }, [props.className])}>
      {embed ||
        <h3 className={b("title")}>
          { title }
        </h3>
      }
      <p className={b("message")}>
        { message }
      </p>
    </div>
  );
}