import React from "react";
import { useParams, useLocation } from "react-router-dom";

import { cookie } from "../lib";
import { AuthContext, IAuthContext } from "../components/AuthProvider";
import { IRect, IRectProp } from "./geom";


export function useAuth(): IAuthContext {
  return React.useContext(AuthContext);
}


export function useDidMount(callback: VoidFunction) {
  React.useEffect(() => callback && callback(), []); // eslint-disable-line react-hooks/exhaustive-deps
}


export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}


export function useQueryParam(name: string) {
  const query = useQuery();
  return query.get(name);
}


export function useRect(ref: React.RefObject<HTMLElement>) {
  const props: IRectProp[] = ["x", "y", "width", "height"];
  const [value, setValue] = React.useState<IRect | null>(null);
  const update = React.useCallback(() => {
    if (!ref || !ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const shouldUpdate = !value || props.some((prop) =>
      value[prop] !== rect[prop]
    );

    shouldUpdate && setValue({
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    });

  }, [ref, value]); // eslint-disable-line react-hooks/exhaustive-deps

  useScroll(update);
  useDidMount(update);
  useResizeObserver(ref.current!, update);
  useResizeObserver(document.body, update);

  return value;
}


export function useResizeObserver(node: HTMLElement, callback: VoidFunction) {
  React.useEffect(() => {
    if (!node || typeof ResizeObserver === "undefined") {
      return;
    }

    const observer = new ResizeObserver(callback);
    observer.observe(node);
  }, [node, callback]);
}


export function useId() {
  const params = useParams();
  return params.id;
}


export function useScroll(callback: VoidFunction) {
  let timer: ReturnType<typeof setTimeout>;
  const handler = () => {
    clearTimeout(timer);
    timer = setTimeout(callback, 100);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  })
}

export function useSigninCode(): string | null {
  const location = useLocation();
  const codeParam = useQueryParam("code");

  if (location.pathname.startsWith("/signin") && codeParam) {
    return codeParam;
  }

  return null;
}


export function useCookies(): Record<string, string> {
  return cookie.parse(document.cookie);
}


export function useCookie(name: string): [string | void, (value: string, meta?: cookie.ICookieMeta) => void] {
  const cookies = useCookies();
  const [value, setValue] = React.useState<string | void>(cookies[name]);

  const setCookie = (value: string, meta: cookie.ICookieMeta = {}) => {
    setValue(value);
    cookie.set(name, value, meta);
  }

  return [value, setCookie];
}


export function useRequest<T = unknown>(request: () => Promise<T>): TRequestHookResult<T> {
  const [data, setData] = React.useState<T | null>(null);
  const [error, setError] = React.useState<Error | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const refresh = () => {
    setLoading(true);

    request()
      .then(setData)
      .catch(setError)
      .then(() => setLoading(false));
  }

  useDidMount(refresh);

  if (loading) {
    return {
      isLoading: true,
      hasData: false,
      data: null,
      hasError: false,
      error: null,

      refresh: refresh,
    };
  }

  if (!loading && error) {
    return {
      isLoading: false,
      hasData: false,
      data: null,
      hasError: true,
      error: error,

      refresh: refresh,
    };
  }

  return {
    isLoading: false,
    hasData: true,
    data: data!,
    hasError: false,
    error: null,

    refresh: refresh,
  };
}

type TRequestHookResult<T> = { isLoading: true, hasData: false, hasError: false, data: null, error: null, refresh: VoidFunction }
  | { isLoading: false, hasData: false, hasError: true, data: null, error: Error, refresh: VoidFunction }
  | { isLoading: false, hasData: true, hasError: false, data: T, error: null, refresh: VoidFunction };
