import { Navigate } from "react-router";

import { cn } from "../../lib";
import { useAuth } from "../../lib/hooks";
import { Header } from "../Header";
import { Footer } from "../Footer";

import "./Page.scss";


const b = cn("page");

export const Page: React.FunctionComponent<TPageProps> = (props) => {
  const auth = useAuth();
  if (props.auth && !auth.user) {
    return <Navigate to="/" />
  }

  const mods: Record<string, boolean> = {};
  if (props.name) {
    mods[props.name] = true;
  }

  return (
    <div className={b(mods, [props.className])}>
      <Header className={b("head")} title={props.title} />

      <div className={b("body")}>
        { props.children }
      </div>

      <Footer className={b("foot")} />
    </div>
  )
};


Page.defaultProps = {
  auth: false,
  className: "",
  name: "",
};


type TPageProps = {
  auth?: boolean;
  title?: string;
  className?: string;
  name?: string;
}