import React from "react";

import {cn} from "../../lib/core/cn";

import {StepSignup} from "./steps/SignupStep";
import {StepSignin} from "./steps/SigninStep";
import {StepCode} from "./steps/CodeStep";
import {StepTerms} from "./steps/TermsStep";
import {Switcher} from "./misc/Switcher";

import "./AuthForm.scss";


const stepsOrder: TAuthStep[] = ["signup", "signin", "codeSent", "terms"];
export const b = cn("auth-form");

export const AuthForm: React.FunctionComponent<TAuthFormProps> = (props) => {
  const [mode, setMode] = React.useState<TAuthMode>(props.mode || "signup")
  const [step, setStep] = React.useState<TAuthStep>(mode);
  const [email, setEmail] = React.useState("");

  const handleModeSwitch = () => {
    const nextMode = mode === "signin" ? "signup" : "signin";
    setMode(nextMode);
    setStep(nextMode);
  }

  const handleSignup = (email: string) => {
    setEmail(email);
    setStep("terms");
  }

  const handleFinish = () =>
    props.onComplete && props.onComplete();

  const embed = props.embed || false;
  const stepOffset = stepsOrder.indexOf(step) * 100;
  const switcher = <Switcher signin={mode === "signin"} onSwitch={handleModeSwitch} />

  return (
    <div className={b({embed}, [props.className])}>
      <div className={b("pager")} style={{transform: `translateX(-${stepOffset}%)`}}>
        <StepSignup
          switcher={switcher}
          onComplete={handleSignup}
          onUserExists={() => setStep("codeSent")}
        />

        <StepSignin
          switcher={switcher}
          onComplete={() => setStep("codeSent")}
        />

        <StepCode
          switcher={switcher}
          onComplete={handleFinish}
        />

        <StepTerms
          email={email}
          onComplete={handleFinish} />
      </div>
    </div>
  );
};

AuthForm.defaultProps = {
  mode: "signup",
  embed: false,
  className: "",
}

type TAuthFormProps = {
  mode?: TAuthMode;
  embed?: boolean;
  className?: string;

  onComplete?: VoidFunction;
}

export type TTermsState = {
  isTermsAccepted: boolean;
  isCookiesAccepted: boolean;
  keepSignedIn: boolean;
}

type TAuthStep = "signin" | "signup" | "codeSent" | "terms";
type TAuthMode = "signin" | "signup";