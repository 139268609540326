import { BrowserRouter } from "react-router-dom";

import { RootProvider } from "../RootProvider/RootProvider";
import { AuthProvider } from "../AuthProvider";
import { AnalyticsProvider } from "../AnalyticsProvider";

import { AppRoutes } from "./AppRoutes";
import "./index.scss";


export function App() {
  return (
    <BrowserRouter>
      <RootProvider>
        <AuthProvider>
          <AnalyticsProvider>
            <AppRoutes />
          </AnalyticsProvider>
        </AuthProvider>
      </RootProvider>
    </BrowserRouter>
  );
}