import { useTheme } from "../ThemeProvider";
import { cn } from "../../lib/core/cn";

import "./Spin.scss";


const block = cn("spin");

export const Spin: React.FunctionComponent<TSpinProps> = (props) => {
  let theme = useTheme();
  if (props.theme) {
    theme = props.theme;
  }

  const { size, global, visible } = props;

  return <span className={block ({ size, global, visible, theme })} />;
}

Spin.defaultProps = {
  size: "s",
  theme: "contrast",
  global: false,
  visible: true,
};


type TSpinProps = {
  size?: TSpinSize;
  theme?: "contrast",
  global?: boolean;
  visible?: boolean;
}


type TSpinSize = "xs" | "s" | "m" | "l" | "xl";