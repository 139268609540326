import React from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth, useDidMount } from "../../lib/hooks";
import { useClient } from "../RootProvider/RootProvider";

import { IAnalyticsContext } from "./AnalyticsProvider.types";


const AnalyticsContext = React.createContext<IAnalyticsContext>({
  track: () => {},

  utmQuery: "",
  isCookiesEnabled: false,
  isAnalyticsLoaded: false,
});

export const AnalyticsProvider: React.FunctionComponent = (props) => {
  const auth = useAuth();
  const client = useClient();
  const utmQuery = useUTMQuery();

  const [isAnalyticsLoaded, setAnalyticsLoaded] = React.useState<boolean>(false);

  const [queue, setQueue] = React.useState<string[]>([]);

  const isCookiesEnabled = auth.user?.isCookiesAccepted || false;

  React.useEffect(() => {
    if (isAnalyticsLoaded || !isCookiesEnabled) {
      return;
    }

    setAnalyticsLoaded(true);
    executeAnalyticScripts()
  }, [isCookiesEnabled, isAnalyticsLoaded]);

  React.useEffect(() => {
    if (!isCookiesEnabled || !isAnalyticsLoaded || !queue.length) {
      return;
    }

    const timeout = setTimeout(() => {
      queue.forEach(track);
      setQueue([]);
    }, 1000);

    return () =>
      clearTimeout(timeout);
  }, [isCookiesEnabled, isAnalyticsLoaded, queue])

  const track = (goal: string) => {
    if (!isCookiesEnabled) {
      setQueue(queue.concat(goal));
      return;
    }

    typeof ym === "function" && ym(86547572, "reachGoal", goal);
    typeof fbq === "function" && fbq("track", goal);

    const deviceId = localStorage.getItem("_ym_uid");
    client.analytics.track(deviceId || "", goal).catch(() => {
      // pass
    });
  };

  const context = {
    track,

    utmQuery,
    isCookiesEnabled,
    isAnalyticsLoaded,
  };

  return (
    <AnalyticsContext.Provider value={ context }>
      { props.children }
    </AnalyticsContext.Provider>
  );
};


export function useAnalytics(): IAnalyticsContext {
  return React.useContext(AnalyticsContext);
}


export function useTrack() {
  return useAnalytics().track;
}


export function useUTMQuery() {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = React.useState<string>("");

  useDidMount(() => {
    const parts: string[] = [];

    searchParams.forEach((val, key) => {
      key = key.toLowerCase();
      if (key.startsWith("utm_")) {
        parts.push([key, val].map(encodeURIComponent).join("="));
      }
    });

    setQuery(parts.join("&"));
  });

  return query;
}


type TTrackProps = {
  goal: string;
}

export const Track: React.FunctionComponent<TTrackProps> = (props) => {
  const track = useTrack();
  useDidMount(() => {
    track(props.goal);
  });

  return null;
}


function executeAnalyticScripts() {
  if (window._ymfml) {
    try {
      window._ymfml();
    }  catch(err) {
      //
    }
  }
}