import { b } from "../Case";


export const Question: React.FunctionComponent<TQuestionProps> = ({ action, children }) =>
  <div className={b("question")}>
    <div className={b("question-hint")}>
      question
    </div>

    <div className={b("question-text")}>
      { children }
    </div>

    {action &&
      <div className={b("question-hint")}>
        { action }
      </div>
    }
  </div>;


export type TQuestionProps = {
  action?: string;
}