import React from "react";

import { cn } from "../../lib";
import { Rect } from "../../lib/geom";
import { useFrame } from "../AspectRatioArea/AspectRatioArea";

import { THeatmapProps, THeatmapConfig, THeatmapPoint } from "./Heatmap.types";
import "./Heatmap.scss";


const b = cn("heatmap");

export const Heatmap: React.FunctionComponent<THeatmapProps> = (props) => {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const frame = useFrame();
  const max = props.max || 5;

  React.useEffect(() => {
    if (new Rect(frame).isEmpty()) {
      return;
    }

    if (!canvas || !canvas.current) {
      return;
    }

    let context = canvas.current.getContext("2d")!;
    draw(context, props.points, max);
  }, [frame, canvas, props.points, max]);

  if (new Rect(frame).isEmpty()) {
    return null;
  }

  return (
    <div className={b()}>
      <img className={b("image")} src={props.image} alt="" />
      <canvas ref={canvas} width={frame.width} height={frame.height} className={b("canvas")} />
    </div>
  );
}


const defaultConfig: THeatmapConfig = {
  blur: 0.85,
  radius: 24,
  colors: {
    0.25: "rgb(0, 0, 255)",
    0.55: "rgb(0, 255, 0)",
    0.85: "yellow",
    1.00: "rgb(255, 0, 0)"
  }
};


const minOpacity = 0.05;
const maxOpacity = 1.00;


function draw(context: CanvasRenderingContext2D, points: THeatmapPoint[], max: number, config: Partial<THeatmapConfig> = {}) {
  const blur = config.blur || defaultConfig.blur;
  const radius = config.radius || defaultConfig.radius;
  const colors = config.colors || defaultConfig.colors;

  let circle = makeTemplatePointCanvas(radius, 1 - blur);

  const width = context.canvas.width;
  const height = context.canvas.height;
  context.clearRect(0, 0, width, height);

  for (let i = 0; i < points.length; i++) {
    let [x, y, value] = points[i];
    x *= width;
    y *= height;
    context.globalAlpha = Math.min(Math.max(value / max, minOpacity), maxOpacity);
    context.drawImage(circle, x - radius, y - radius);
  }

  let colored = context.getImageData(0, 0, width, height);
  let palette = makeColorPalette(colors);

  let pixels = colored.data;
  for (let i = 0; i < pixels.length; i += 4) {
    let j = pixels[i + 3] * 4;

    pixels[i] = palette[j];
    pixels[i + 1] = palette[j + 1];
    pixels[i + 2] = palette[j + 2];
  }

  context.putImageData(colored, 0, 0);
}


function makeTemplatePointCanvas(radius: number, blurFactor: number) {
  const canvas = document.createElement("canvas");
  canvas.width = canvas.height = radius * 2;

  const context = canvas.getContext("2d")!;

  let x = radius;
  let y = radius;

  let g = context.createRadialGradient(x, y, radius * blurFactor, x, y, radius);
  g.addColorStop(0, "rgba(0, 0, 0, 1)");
  g.addColorStop(1, "rgba(0, 0, 0, 0)");
  context.fillStyle = g;
  context.fillRect(0, 0, 2 * radius, 2 * radius);

  return canvas;
}


function makeColorPalette(colors: Record<number, string>) {
  let canvas = document.createElement("canvas");
  canvas.width = 1;
  canvas.height = 256;

  let context = canvas.getContext("2d")!;
  let gradient = context.createLinearGradient(0, 0, 0, 256);

  for (let [offset, color] of Object.entries(colors)) {
    gradient.addColorStop(Number(offset), color);
  }

  context.fillStyle = gradient;
  context.fillRect(0, 0, 1, 256);

  return context.getImageData(0, 0, 1, 256).data;
}
