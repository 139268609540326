import { Fragment } from "react";

import { useAuth, useId, useRequest } from "../../lib/hooks";
import { useClient } from "../RootProvider";
import { Spin, Error } from "..";
import { ResearchType, TPublicResearch } from "../../lib/types";
import { ResearchQuestion, ResearchHeatmap, ResearchSideBySide } from "../Research";

import { TResearchControllerProps } from "./ResearchController.types";


export const ResearchController: React.FunctionComponent<TResearchControllerProps> = (props) => {
  const client = useClient();

  const id = useId();
  const auth = useAuth();
  const request = useRequest(() => client.research.fetch(id!));

  const isAuthor = request.hasData && auth.user !== null
    && auth.user.username === request.data.username;

  const handleMetaUpdate = (meta: any) =>
    isAuthor && client.research.update(id!, meta)
      .catch(console.error);

  return (
    <Fragment>
      {request.isLoading &&
        <Spin size="l" global />
      }

      {request.hasError &&
        <Error value={request.error} />
      }

      {request.hasData &&
        researchFactory({
          research: request.data,
          credits: !isAuthor,

          onMetaUpdated: handleMetaUpdate,
        })
      }
    </Fragment>
  )
}


function researchFactory(options: TFactoryOptions): JSX.Element | null {
  const {research, ...props} = options;

  if (research.type === ResearchType.question) {
    return <ResearchQuestion research={research} {...props} />;
  }

  if (research.type === ResearchType.heatmap) {
    return <ResearchHeatmap research={research} {...props} />;
  }

  if (research.type === ResearchType.sideBySide) {
    return <ResearchSideBySide research={research} {...props} />;
  }

  return null;
}


type TFactoryOptions = {
  research: TPublicResearch;
  credits: boolean;

  onMetaUpdated: (meta: any) => void;
}