import { Arrow, Mark, Recent, Cross, Logout, PlusFilled, Star, Back } from "./Icon";

const Icon = {
  Arrow,
  Mark,
  Recent,
  Cross,
  Logout,
  PlusFilled,
  Star,
  Back,
};

export default Icon;