import { Navigate, useNavigate, useParams } from "react-router-dom";

import { useClient } from "../../components/RootProvider";
import { useTrack } from "../../components/AnalyticsProvider";
import { Page } from "../../components/Page";
import { NewResearchController } from "../../components/NewResearchController";
import templates from "../../lib/templates";


export const NewResearchPage: React.FunctionComponent = (props) => {
  const track = useTrack();
  const client = useClient();
  const navigate = useNavigate();

  const { type } = useParams();

  const template = templates.filter(template =>
    template.type === type).shift();

  if (!template) {
    return <Navigate to="/" />;
  }

  const handleResearch = (id: string) => {
    track(`create-research-${type}`);
    navigate(`/researches/${id}`);
  }

  return (
    <Page auth title="New Task">
      <NewResearchController client={client} template={template} onResearch={handleResearch} />
    </Page>
  );
}