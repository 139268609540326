import { Fragment } from "react";

import { TSideBySideModerationResearch, TSideBySideResearchResponse } from "../../../lib/types";
import { Button } from "../../Button";

import { b, Moderation, TModerationProps, TModerationState } from "../Moderation";
import { Row } from "../misc";
import { SideBySidePreview } from "../misc/SideBySidePreview";


export class SideBySide extends Moderation<TSideBySideProps, TSideBySideState> {
  private readonly responses: TSideBySideResearchResponse[];
  private readonly responseById: Map<number, TLocalResponse>;

  constructor(props: TSideBySideProps) {
    super(props);

    const responses = this.responses = props.research.responses;

    if (this.props.research.state !== "completed") {
      responses.forEach(response => {
        response.approved = undefined;
      });
    }

    this.responseById = responses.reduce((map, response) =>
      map.set(response.id, response), new Map());

    const badIds = responses.filter(r => r.approved === false).map(r => r.id);

    this.state = Object.assign({}, this.state, {
      badIds: new Set(badIds),
    });

    this.onAnswerClick = this.onAnswerClick.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  onAnswerClick(id: number) {
    const response = this.responseById.get(id);
    if (!response) {
      return;
    }

    const nextState = new Set(this.state.badIds);

    const isExist = nextState.has(id);
    isExist ? nextState.delete(id) : nextState.add(id);

    this.setState({
      badIds: nextState,
    });
  }

  onSubmitClick() {
    this.props.onSubmit(Array.from(this.state.badIds));
  }

  renderTask(): JSX.Element | null {
    const {task} = this.props.research;

    return (
      <Row className={b("grid")}>
        <SideBySidePreview label="A" image={task.a.url} />
        <SideBySidePreview label="B" image={task.b.url} />
      </Row>
    );
  }

  renderResponses(): JSX.Element {
    const {badIds} = this.state;
    console.log(badIds);

    return (
      <Row centered>
        <div className={b("responses", { selectable: true })}>
          {this.responses.map(({id, data}) =>
            <div key={id}  className={b("responses-item", {bad: badIds.has(id)})} onClick={() => this.onAnswerClick(id)}>
              <Group title="What’s the difference?">
                { data.difference }
              </Group>

              <Group title={`Why ${data.whichBetter} is better?`}>
                { data.whyBetter }
              </Group>
            </div>
          )}
        </div>
      </Row>
    );
  }

  renderBody(): JSX.Element {
    return (
      <Fragment>
        {this.renderTask()}
        {this.renderResponses()}
      </Fragment>
    )
  }

  renderFoot(): JSX.Element {
    return (
      <Row centered className={b("foot")}>
        <Button type="submit" className={b("submit")} disabled={!this.canSubmit} onClick={this.onSubmitClick}>
          Submit
        </Button>
      </Row>
    );
  }
}


type TSideBySideProps = TModerationProps & {
  research: TSideBySideModerationResearch;
}

type TSideBySideState = TModerationState & {
  badIds: Set<number>;
};

type TLocalResponse = {
  id: number;
  value: string;
  approved: boolean | void;
  normalized: string;
};


const Group: React.FunctionComponent<TGroupProps> = (props) =>
  <div className={b("group")}>
    <b>{ props.title }</b>
    <p>{ props.children }</p>
  </div>

type TGroupProps = {
  title: string;
}
