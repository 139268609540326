import { ReactElement, useRef } from "react";
import { useParams } from "react-router";

import { Title } from "../Title";
import { cn } from "../../lib/core/cn";
import { GroupStatistic } from "../GroupStatistic/GroupStatistic";

import "./Example.scss";


const b = cn("example");

export const Example: React.FunctionComponent<TExampleProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const params = useParams()
  const postSlug = params.slug || "";

  // TODO: FIX THIS
  if (!Object.keys(posts).includes(postSlug)) {
    return <h1>Not Found</h1>;
  }

  const content = posts[postSlug];
  return (
    <div className={b({}, [props.className])} ref={ref}>
      <div className={b("content")}>
        { content }
      </div>
    </div>
  );
};

const posts: Record<string, ReactElement> = {
  "icon-recognition": (
    <>
      <Title size="large">
        What Do Your Users Actually See in an Icon?
      </Title>

      <p>
        Imagine you sell electronic devices and you need an icon for drones.
      </p>
      <div className={b("image")}>
        <img src="/blog-assets/menu-example.png" alt="" />
      </div>
      <p>
        We don’t yet have a single, widely recognised symbol for that. There are many options.
      </p>
      <p>
         Here is a few examples from Google Images:
      </p>
      <div className={b("image")}>
        <img src="/blog-assets/icons-examples.png?v=1" alt="" />
      </div>
      <p>So which is most clearly a drone?</p>

      <p>
        Let's create a <span>single question</span> survey, where respondents type their answer for each icon.
      </p>
      <div className={b("image")}>
        <video autoPlay muted loop playsInline>
          <source src="https://res.cloudinary.com/hk6qucfw5/video/upload/v1638007905/blog/ask-question-creation_lfzode.mp4" type="video/mp4" />
          <source src="https://res.cloudinary.com/hk6qucfw5/video/upload/v1638007906/blog/ask-question-creation_y8cuk6.ogv" type="video/ogg" />
          <source src="https://res.cloudinary.com/hk6qucfw5/video/upload/v1638007906/blog/ask-question-creation_cvpmhr.webm" type="video/webm" />
        </video>
      </div>

      <p>
        Why don't we use polls? We don’t really want to give any hints of the the right answer. It would be much better to see what respondents come up with on their own.
      </p>

      <p>
        To calculate results ASK THE CROWD provide <span>Tags</span>.<br/>You can mark answers with as  <span>A, B or C</span> and calculate statistic autmaticaly.
      </p>

      <p>
        In this example, we can use <span>A</span> for a drone and <span>B</span> for anything else.
      </p>

      <div className={b("image")}>
        <video autoPlay muted loop playsInline>
          <source src="https://res.cloudinary.com/hk6qucfw5/video/upload/v1641888567/blog/ask-question-tags_wvgksr.mp4" type="video/mp4" />
          <source src="https://res.cloudinary.com/hk6qucfw5/video/upload/v1641888568/blog/ask-question-tags_ebwjfl.ogv" type="video/ogg" />
          <source src="https://res.cloudinary.com/hk6qucfw5/video/upload/v1641888567/blog/ask-question-tags_gl09w5.webm" type="video/webm" />
        </video>
      </div>
      <p>
        Icons by Kantor Tegalsari, Dima Lagunov, ibrandify, Alequinho, Vectors Market and mikicon from <a href="https://thenounproject.com" rel="noreferrer" target="_blank">NounProject.com</a>.
      </p>
      <h3>
        Tasks Results
      </h3>
      <div className={b("tasks")}>
        <GroupStatistic ids={[
          "5d01c96a-9a2c-4a1e-a791-bf13c64b4f0b",
          "342ca75b-3265-4399-97c3-39f98dd3ddee",
          "2114a04a-000c-4465-9a5a-cf12a58474f0",
          "b5c075b3-1bd6-496c-a6a7-8e02cff4322d",
          "8de4b871-2cd3-4e62-98da-a95bc2803f3d",
          "0457bd6c-6d83-4b3f-91e4-c95889e07fc4"
        ]}/>
      </div>
      <h3>
        Resolution
      </h3>
      <p>
        Looks like top view variants have a little bit better results. If you check tasks answers you will see that users recognize a different object in icons. For a top view they found <span>spinners</span>, and for a direct view — <span>scales</span>.
      </p>
      <p>
        Keep it in mind while doing a new variant. And don't forget to <span>recheck it</span> at ASK THE CROWD 🙂..
      </p>
    </>
  ),
  "product-designer-thoughts": (
    <>
      <Title size="large">
        How dogs should wear paints
      </Title>

      <p>
        Designer ask a lot of questions while creating new things. Most of them he or she solves by professional or life experience.
      </p>
      <p>
        But how much you can trust these desicions? Not much. Let's check why.
      </p>
      <p>
        For example. Let's check how good random people understand this selector. The only person who really now is an author.
      </p>
      <p>
        (image with ios selector)
      </p>
      <p>
        Good designers doesn't trust themselves and checks their work on other people. If the most random people understod your design — it is good one.
      </p>
      <p>
        To solve this problem we create this service. You can ask a question, attach a design and get tens of responses in 5 minutes.
      </p>
      <p>
        For example, lets research this dogs paints.
      </p>
      <p>
        Which option is more convenient and why?
      </p>
      <p>
        Whire down advantage and disadvantages of case A and B.
      </p>
    </>
  ),
  "how-to-check-contrast": (
    <>
      <Title size="large">
        How to check contrast on users
      </Title>

      <p>
        Imaging we want to create a very light weight page like this.
      </p>
      <p>
        Page looks good at designer's display. But how do users will see it?
      </p>
      <p>
        Unfortunately for the most people in the world it looks like this:
      </p>
      <p>
        So we need to find the right color. Let's create a <span>Where to Click </span> task. We will show a few color examples for users and ask them which background is visible enough.
      </p>
      <p>
        In this people will have to click to the image part we ask. After all he have a heatmap and we can markup area to view percents of clicks.
      </p>
      <h3>Resolution</h3>
      <p>
        As we can see #123456 is have enough contrast, but the design is not looks as light as before. Now we have to accept it or redesign it.
      </p>
      <p>
        Look at these examples. Very popular websites already have a solution — borders. If you wonder which border color is good — check out that task in the <span>Tasks Results</span> section.
      </p>
    </>
  ),
  "banana-design": (
    <>
      <Title size="large">
        Two simple tests for quick UX research
      </Title>

      <p>
        The designer’s job is all about decisions. As an author, we already know how our design works. And usually, we aren't sure will users understand it or not.
      </p>
      <p>
        Hopefully, we created this service to get these answers quickly (in 10 minutes) using just two popular tests: <span>simple question</span> and <span>where to click</span>.
      </p>
      <p>
        So let's test, for example, a banana 🍌 interface. How do people peel it? From which side? Get your assumptions.
      </p>
      <div className={b("image")}>
        <img src="https://res.cloudinary.com/hk6qucfw5/image/upload/v1642079929/blog/Frame_5_oy11oc.png" alt="" />
      </div>
      <p>
        First of all, let's create a <span>where to click</span> test to check the difference of answers. Just ask users "From which side do you peel a banana"? As a result, we will get a heatmap:
      </p>
      <div className={b("image")}>
        <img src="https://res.cloudinary.com/hk6qucfw5/image/upload/v1642088058/blog/Screenshot_2022-01-13_at_18.18_1_ie6c3k.png" alt="" />
      </div>
      <p>
        Surprise! There are different opinions. Let's find out why.
      </p>
      <p>
        To do this we just need to create a <span>simple question</span> test with the question "From which side do you peel a banana? Why are you like this way?"
      </p>
      <p>
        As a result, we will get a lot of opinions:
      </p>
      <div className={b("image")}>
        <img src="https://res.cloudinary.com/hk6qucfw5/image/upload/v1642088388/blog/Screenshot_2022-01-13_at_18.37_1_rdndtm.png" alt="" />
      </div>
      <p>
        In this way, your can test your own interfaces and check how good it is.
      </p>
      <p>
        If it's good enough you can create a new design approach and redo the test, compare results and <span>check if the new design is better</span>.
      </p>
      <p>
        You can do a few researches on our service for free while we are in beta. Sign up form is just below.
      </p>
      <h3>
        Tasks Results
      </h3>
      <div className={b("tasks")}>
        <GroupStatistic ids={[
          "0eb0990f-8827-4f2b-b742-7e4ab9d12fed",
          "ec98c3e2-b8c7-4955-8a4f-005b4b5d2c4d"
        ]}/>
      </div>
    </>
  ),
};

type TExampleProps = {
  className?: string;
}