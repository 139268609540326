import { Navigate } from "react-router-dom";

import { useAuth } from "../../lib/hooks";
import { AuthForm } from "../../components/AuthForm";
import { Page } from "../../components/Page";


export const AuthPage: React.FunctionComponent<TAuthPageProps> = (props) => {
  const auth = useAuth();
  if (auth.user) {
    return <Navigate to="/" />;
  }

  return (
    <Page name="login">
      <AuthForm className="page__content" />
    </Page>
  );
}


type TAuthPageProps = {
  step: string;
}