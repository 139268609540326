import { b } from "../Case";


export const RadioList: React.FunctionComponent<TRadioListProps> = (props) =>
  <div className={b("radio-list")}>
    {props.items.map(item =>
      <RadioItem key={item.value} checked={item.value === props.value} {...item} onClick={props.onChange} />
    )}
  </div>

const RadioItem: React.FunctionComponent<TRadioItemProps> = ({ checked, ...props }) =>
  <span className={b("radio-list-item", { checked })} onClick={() => props.onClick(props.value)}>
    {props.label}
  </span>


export type TRadioListProps = {
  items: TOption[];
  value: string | null;

  onChange: (value: string) => void;
}

export type TRadioItemProps = TOption & {
  checked: boolean;

  onClick: (value: string) => void;
}

export type TOption = {
  label: string;
  value: string;
}