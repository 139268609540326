import { b } from "../Research";


const SideBySidePreview: React.FunctionComponent<TSideBySidePreviewProps> = (props) =>
  <a href={props.image} className={b("preview")} target="_blank" rel="noreferrer">
    <div className={b("preview-container")}>
      <div className={b("preview-image")} style={{ backgroundImage: `url(${props.image})` }} />
    </div>
    <div className={b("preview-footer")}>
      {props.children}
    </div>
  </a>


const Label: React.FunctionComponent = (props) =>
  <span className={b("preview-label")}>
    {props.children}
  </span>


const Value: React.FunctionComponent = (props) =>
  <span className={b("preview-value")}>
    {props.children}
  </span>


type TSideBySidePreviewProps = {
  image: string;
}

export default Object.assign(SideBySidePreview, {
  Label,
  Value,
});