import React from "react";

import {Title} from "../../Title";
import {SubmitableInput} from "../../SubmitableInput";
import {isLooksLikeEmail} from "../../../lib/validations";

import {b} from "../AuthForm";


export const EmailForm: React.FunctionComponent<TEmailFormProps> = (props) => {
  const [shake, setShake] = React.useState(false);

  const handleSubmit = (email: string) => {
    if (!email.length || !isLooksLikeEmail(email)) {
      return setShake(true);
    }

    props.onSubmit(email);
  }

  return (
    <div className={b("form")}>
      <Title size="large">
        {props.title}
      </Title>

      <SubmitableInput
        type="email"
        shake={shake}
        submitting={props.submitting}
        placeholder="Enter your email address"
        onSubmit={handleSubmit}
        onAnimationEnd={() => setShake(false)}
      />

      {props.children}
    </div>
  )
}


type TEmailFormProps = {
  title: string;
  submitting: boolean;

  onSubmit: (email: string) => void;
}