import { errors, strings } from "..";

import {
  TAuthToken,
  IUserInfo,
  TPublicResearch,
  ICommonTask,
  TTask,
  TSubmitssionToken,
  TModerationResearch,
  IImageMeta,
  TAdminResearch,
  ISideBySideTaskData,
  IHeatmapTaskData,
  IQuestionTaskData,
} from "../types";

import {
  isFalse,
  isObject,
  hasOwnProperty,
  isLooksLikeEmail,
  typeOf
} from "../validations";

import {
  IAPIClient,
  IHTTPClient,
  IResponse,
  ISignupRequest,
  TAuthResult,
  TRequestOptions,
  TUserUpdateRequest,
} from "./types";

import {
  createErrorFromResponse,
  ForbiddenError,
  HTTPError,
  InternalServerError,
  NetworkError,
  NotFoundError,
  ResponseParseError,
  UnauthorizedError,
  UnexpectedResponseFormat,
} from "./errors";


export const IMAGE_MAX_SIZE_IN_BYTES = 1024 * 10 * 10 * 10;


export const createClient = (host: string, httpClient: IHTTPClient): IAPIClient => {
  let token = "";

  host = strings.trimSuffix(host, "/");

  const invoke = <T = unknown>(endpoint: string, options: TRequestOptions = {}): Promise<T> => {
    const resolveWithGeneric = (value: IResponse) =>
      handleServerResponse<T>(value);

    if (!hasOwnProperty(options, "token")) {
      options.token = token;
    }

    return httpClient.request(host + endpoint, buildRequestOptions(options))
      .catch(rejectWithNetworkError)
      .then(resolveWithGeneric)
      .catch(rejectIfValueTypeIsError);
  };

  function userCreate(request: ISignupRequest): Promise<TAuthToken> {
    if (!isLooksLikeEmail(request.email)) {
      return reject(new errors.EmailValidationError());
    }

    if (isFalse(request.isTermsAccepted)) {
      return reject(new errors.InputValidationError("Terms must be accepted"));
    }

    const promise = invoke<TAuthResult>("/users", {
      token: "",
      method: "POST",
      payload: request,
    });


    return promise.then(result => result.token);
  }

  function userFetch(): Promise<IUserInfo> {
    return invoke<IUserInfo>("/users/me");
  }

  function userUpdate(request: TUserUpdateRequest): Promise<null> {
    return invoke("/users/me", {
      method: "PUT",
      payload: request,
    });
  }

  function userDelete(): Promise<null> {
    return invoke<null>("/users/me", {
      method: "DELETE",
    });
  }

  function requestLoginCode(email: string): Promise<null> {
    if (isFalse(isLooksLikeEmail(email))) {
      return reject(new errors.InputValidationError("Email is incorrect"));
    }

    return invoke<null>("/users/send-code", {
      token: "",
      method: "POST",
      payload: { email },
    });
  }

  function submitLoginCode(code: string): Promise<TAuthToken> {
    const promise = invoke<TAuthResult>("/auth", {
      token: "",
      method: "POST",
      payload: { code },
    });

    return promise.then(result => result.token);
  }

  function listResearches(): Promise<TPublicResearch[]> {
    return invoke<TPublicResearch[]>("/researches");
  }

  function createResearch(templateId: number, inputData: ICommonTask | ISideBySideTaskData): Promise<TPublicResearch> {
    if (templateId < 1 || templateId > 3) {
      return reject(new errors.InputValidationError(`Unknown task template id '${templateId}'`));
    }

    if (!inputData.question) {
      return reject(new errors.InputValidationError("Required field 'input_data.question' is missing"));
    }

    if (templateId === 2) {
      const { image } = inputData as ICommonTask;
      if (!image) {
        return reject(new errors.InputValidationError("Required field 'input_data.image' is missing"));
      }
    }

    if (templateId === 3) {
      const { a, b } = inputData as ISideBySideTaskData;
      if (!a) {
        return reject(new errors.InputValidationError("Required field 'input_data.a' is missing"));
      }

      if (!b) {
        return reject(new errors.InputValidationError("Required field 'input_data.b' is missing"));
      }
    }

    return invoke<TPublicResearch>("/researches", {
      method: "POST",
      payload: {
        template_id: templateId,
        input_data: inputData,
      },
    });
  }

  function fetchResearch(id: string): Promise<TPublicResearch> {
    return invoke<TPublicResearch>(`/researches/${id}`);
  }

  function updateResearch(id: string, meta: any): Promise<null> {
    return invoke<null>(`/researches/${id}/meta`, {
      method: "POST",
      payload: meta,
    });
  }

  function fetchTask(id: string): Promise<TTask> {
    return invoke<TTask>(`/cases/${id}`, {
      token: "",
    });
  }

  function submitTask(id: string, output: any): Promise<TSubmitssionToken> {
    const promise = invoke<TAuthResult>(`/cases/${id}`, {
      token: "",
      method: "POST",
      payload: { output },
    });

    return promise.then(result => result.token);
  }

  function fetchModerationTask(id: string): Promise<TModerationResearch> {
    return invoke<TModerationResearch>(`/moderation/researches/${id}`);
  }

  function cleanupResearch(id: string, badIds: number[]): Promise<null> {
    return invoke<null>(`/moderation/researches/${id}/cleanup`, {
      method: "POST",
      payload: {
        bad_response_ids: badIds,
      },
    });
  }

  function uploadImage(file: File): Promise<IImageMeta> {
    if (!strings.hasPrefix(file.type, "image/")) {
      return reject(new errors.InputValidationError("Only files with type 'image/*' allowed"));
    }

    // if (file.size > IMAGE_MAX_SIZE_IN_BYTES) {
    //   return reject(new errors.InputValidationError("Image is too large"));
    // }

    const data = new FormData();
    data.append("image", file);

    return invoke<IImageMeta>("/images", {
      method: "POST",
      payload: data,
    });
  }

  function trackUserAction(deviceId: string, goal: string): Promise<null> {
    return invoke<null>("/tracks", {
      method: "POST",
      payload: {
        device_id: deviceId,
        goal: goal,
      },
    });
  }

  function fetchAdminResearches(state?: string): Promise<TAdminResearch[]> {
    return invoke<TAdminResearch[]>(`/admin/researches?state=${state}`);
  }

  function rejectResearch(id: string): Promise<null> {
    return invoke<null>(`/admin/researches/${id}`, {
      method: "DELETE",
    });
  }

  const user = {
    create: userCreate,
    fetch: userFetch,
    update: userUpdate,
    delete: userDelete,
    requestCode: requestLoginCode,
    submitCode: submitLoginCode,
  };

  const task = {
    fetch: fetchTask,
    submit: submitTask,
  };

  const research = {
    list: listResearches,
    create: createResearch,
    fetch: fetchResearch,
    update: updateResearch,
  };

  const moderation = {
    fetch: fetchModerationTask,
    cleanup: cleanupResearch,
  };

  const misc = {
    uploadImage: uploadImage,
  };

  const analytics = {
    track: trackUserAction,
  };

  const admin = {
    researches: fetchAdminResearches,
    reject: rejectResearch,
  };

  const setToken = (authToken: TAuthToken) => {
    token = authToken;
  }

  return { invoke, user, task, research, moderation, misc, analytics, admin, setToken };
};


export function handleServerResponse<T>(response: IResponse): T {
  const status = response.status;
  if (status === 401) {
    throw new UnauthorizedError(response);
  }

  if (status === 403) {
    throw new ForbiddenError(response);
  }

  if (status === 404) {
    throw new NotFoundError(response);
  }

  if (status === 500) {
    throw new InternalServerError(response);
  }

  if (!response.ok) {
    throw new HTTPError(response);
  }

  let payload;
  try {
    payload = JSON.parse(response.body);
  } catch (err) {
    throw new ResponseParseError(response);
  }

  if (!isObject(payload)) {
    throw new UnexpectedResponseFormat(`Response must be an object but got '${payload}'`);
  }

  const isResponseSuccessfull = payload.ok as boolean;
  if (typeOf(isResponseSuccessfull) !== "Boolean") {
    throw new UnexpectedResponseFormat("Required field `ok` is missing");
  }

  if (!isResponseSuccessfull) {
    throw createErrorFromResponse(payload.error, response);
  }

  return (payload.result || null) as T;
}


export function buildRequestOptions(options: TRequestOptions = {}): RequestInit {
  const defaultHeaders: Record<string, any> = {};
  if (options.token) {
    defaultHeaders["X-ASK-TOKEN"] = options.token;
  }

  if (isObject(options.payload)) {
    defaultHeaders["Content-Type"] = "application/json";
  }

  const method = options.method || "GET";
  const headers = Object.assign(defaultHeaders, options.headers);

  let body: BodyInit | null = null;
  if (typeof options.payload === "string") {
    body = options.payload;
  }

  if (options.payload instanceof FormData) {
    body = options.payload;
  }

  if (isObject(options.payload)) {
    body = JSON.stringify(options.payload);
  }

  return { method, headers, body };
}


function rejectWithNetworkError(error: Error): Promise<IResponse> {
  return reject(new NetworkError(error));
}


function rejectIfValueTypeIsError(value: any) {
  if (value instanceof errors.AskError) {
    return reject(value);
  }

  if (value instanceof Error) {
    return reject(new errors.InternalError(value));
  }

  return value;
}


function reject(error: any) {
  return Promise.reject(error);
}