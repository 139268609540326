import { AuthPage } from "..";
import { Page } from "../../components/Page";
import { TemplateList } from "../../components/TemplateList";
import { useAuth } from "../../lib/hooks";
import templates from "../../lib/templates";

import "./HomePage.scss";


export function Home() {
  const auth = useAuth();

  if (!auth.user) {
    return <AuthPage step="signup" />
  }

  return (
    <Page name="home" title="New Task">
      <TemplateList items={templates} className="page__content" />
    </Page>
  );
}