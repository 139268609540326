import { Fragment } from "react";

import { TSideBySideResearch } from "../../../lib/types";
import { Accordion } from "../../Accordion";

import { b, Research } from "../Research";
import { TResearchProps, TResearchState } from "../Research";
import { Row } from "../misc";
import SideBySidePreview from "../misc/SideBySidePreview";


export class ResearchSideBySide extends Research<TSideBySideResearchProps, TSideBySideResearchState> {
  private readonly whyOptionA: string[];
  private readonly whyOptionB: string[];
  private readonly aValue: number;
  private readonly bValue: number;

  constructor(props: TSideBySideResearchProps) {
    super(props);

    this.state = Object.assign({}, this.state, {
      tagsSelected: props.research.meta || {},
    });

    const whyOptionA: string[] = [];
    const whyOptionB: string[] = [];

    props.research.responses.forEach(response => {
      const { data } = response;

      if (data.whichBetter === "a") {
        whyOptionA.push((data.whyBetter || "").trim());
      }

      if (data.whichBetter === "b") {
        whyOptionB.push((data.whyBetter || "").trim());
      }
    });

    this.whyOptionA = whyOptionA;
    this.whyOptionB = whyOptionB;

    this.aValue = Math.floor(whyOptionA.length / (whyOptionA.length + whyOptionB.length) * 100);
    this.bValue = Math.floor(whyOptionB.length / (whyOptionA.length + whyOptionB.length) * 100);
  }

  renderBody(): JSX.Element {
    return (
      <Fragment>
        {this.renderTask()}
        {this.renderData()}
      </Fragment>
    );
  }

  renderTask(): JSX.Element | null {
    const {task} = this.props.research;
    return (
      <Row className={b("grid")}>
        <SideBySidePreview image={task.a.url}>
          <SideBySidePreview.Label>
            A
          </SideBySidePreview.Label>
          <SideBySidePreview.Value>
            {this.aValue}
          </SideBySidePreview.Value>
        </SideBySidePreview>

        <SideBySidePreview image={task.b.url}>
          <SideBySidePreview.Value>
            {this.bValue}
          </SideBySidePreview.Value>
          <SideBySidePreview.Label>
            B
          </SideBySidePreview.Label>
        </SideBySidePreview>
      </Row>
    );
  }

  renderData(): JSX.Element {
    return (
      <Row centered>
        {this.renderAnswers("WHY A?", this.whyOptionA)}
        {this.renderAnswers("WHY B?", this.whyOptionB)}
      </Row>
    )
  }

  renderAnswers(label: string, answers: string[]): JSX.Element {
    return (
      <Accordion title={label}>
        {answers.map((answer, index) =>
          <div className={b("answer")} key={index}>
            {answer}
          </div>
        )}
      </Accordion>
    );
  }
}


export type TSideBySideResearchProps = TResearchProps & {
  research: TSideBySideResearch;
}

export type TSideBySideResearchState = TResearchState & {

}