import { useParams } from "react-router";

import { Content } from "../../components/Content/Content";
import { Page } from "../../components/Page/Page";
import { ResearchController } from "../../components/ResearchController";


export const ResearchPage: React.FunctionComponent = () => {
  const params = useParams();
  if (!params.id) {
    return null; // TODO: This should never happened
  }

  return (
    <Page name="research">
      <Content className="page__content backface">
        <ResearchController id={params.id} />
      </Content>
    </Page>
  );
}
