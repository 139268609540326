import { Fragment } from "react";

import { strings } from "../../../lib";
import { InputValidationError } from "../../../lib/core/errors";
import { Error } from "../../Error";
import { Textarea } from "../../Textarea";
import { ISideBySideTaskData } from "../../../lib/types";

import { b, Case } from "../Case";
import { Row } from "../misc";
import { TCaseProps, TCaseState } from "../Case.types";
import { RadioList } from "../misc/RadioList";


export class SideBySideCase extends Case<TCaseSideBySideProps, TCaseSideBySideState> {
  private readonly options = [
    {
      label: "Option A",
      value: "a",
    },
    {
      label: "Option B",
      value: "b",
    }
  ];

  constructor(props: TCaseSideBySideProps) {
    super(props);
    this.state = Object.assign({}, this.state, {
      difference: "",
      whyBetter: "",
      whichBetter: "a",
    });

    this.onWhyChange = this.onWhyChange.bind(this);
    this.onDiffChange = this.onDiffChange.bind(this);
    this.onRadioChanged = this.onRadioChanged.bind(this);
  }

  get isValid(): boolean {
    const isDiffValid = this.isTextValid(this.state.difference);
    const isWhyValid = this.isTextValid(this.state.whyBetter);
    return isDiffValid && isWhyValid;
  }

  get canSubmit(): boolean {
    const { difference, whyBetter, whichBetter } = this.state;
    if (!difference.length) {
      return false;
    }

    if (!whyBetter.length) {
      return false;
    }

    if (whichBetter !== "a" && whichBetter !== "b") {
      return false;
    }

    return this.isValid;
  }

  isTextValid(value: string): boolean {
    const letters = value.match(/./ug) || [];
    return letters.every(char => {
      return !strings.isLetter(char) || strings.isEnglishLetter(char);
    });
  }

  serialize() {
    const { difference, whyBetter, whichBetter } = this.state;
    return { difference, whyBetter, whichBetter };
  }

  onWhyChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      whyBetter: event.target.value,
    });
  }

  onDiffChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      difference: event.target.value,
    });
  }

  onRadioChanged(value: string) {
    this.setState({
      whichBetter: value,
    });
  }

  renderBody(): JSX.Element {
    const {data} = this.props;
    return (
      <Fragment>
        <Row centered className={b("options")}>
          <a href={data.a.url} target="_blank" rel="noreferrer">Option A</a>
          <a href={data.b.url} target="_blank" rel="noreferrer">Option B</a>
        </Row>

        <Row centered>
          <p>What the difference have you found?</p>
          <Textarea
            className={b("textarea")}
            placeholder="Your answer..."
            value={this.state.difference}
            name="difference"
            onChange={this.onDiffChange}
          />
        </Row>

        <Row centered>
          <p>Which version is better?</p>
          <RadioList items={this.options} value={this.state.whichBetter} onChange={this.onRadioChanged} />
        </Row>

        <Row centered>
          <p>Describe your opinion in detail. General answers like “better” are not allowed.</p>
          <Textarea
            className={b("textarea")}
            placeholder="Your answer..."
            value={this.state.whyBetter}
            name="why"
            onChange={this.onWhyChange}
          />
        </Row>

        {this.isValid ||
          <Row centered>
            <Error value={new InputValidationError("Only answers in English are allowed")} embed />
          </Row>
        }
      </Fragment>
    )
  }
}

type TCaseSideBySideProps = TCaseProps & {
  data: ISideBySideTaskData;
}

type TCaseSideBySideState = TCaseState & {
  difference: string;
  whichBetter: string;
  whyBetter: string;
}