import { cn } from "../../lib";
import Icon from "../Icon";

import "./Checkbox.scss";


const b = cn("checkbox");

export const Checkbox: React.FunctionComponent<React.InputHTMLAttributes<HTMLInputElement>> = ({children, className, ...rest}) => {
  return (
    <label className={b({}, [className])}>
      <input type="checkbox" {...rest} />

      <i className={b("mark")}>
        <Icon.Mark />
      </i>

      {children &&
        <span className={b("label")}>
          { children }
        </span>
      }
    </label>
  );
}