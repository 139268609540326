export class AskError extends Error {
  public readonly title: string = "";
  public readonly userFriendly: boolean = false;

  constructor(message: string) {
    super(message);

    this.name = this.constructor.name;
    this.message = message;

    // Error.captureStackTrace(this, this.constructor);
  }

  get data(): Record<string, any> | null {
    return null;
  }

  toJSON() {
    const json: Record<string, any> = {
      name: this.name,
      message: this.message,
      stacktrack: this.stack,
    };

    if (this.data) {
      json.data = this.data;
    }

    return json;
  }
}

export class InternalError extends AskError {
  constructor(error: Error) {
    super(error.message);
    this.stack = error.stack;
  }
}

export class ClientError extends AskError {

}

export class NotImplemented extends AskError {
  message = "The requested feature is not implemented. Yet."
}

export class InputValidationError extends AskError {
  title = "Validation error";
  userFriendly = true;
}

export class EmailValidationError extends InputValidationError {
  userFriendly = true;

  constructor() {
    super("Email is incorrect");
  }
}

export class LoginCodeValidationError extends InputValidationError {
  userFriendly = true;

  constructor() {
    super("Login code is incorrect");
  }
}

export class UniquinessError extends AskError {}
export class EntityUniquinessError extends AskError {
  userFriendly = true;

  constructor(entity: string) {
    super(`${entity} has already been taken`);
  }
}

export class ShouldNeverHaveHappened extends AskError {}

export class UserFriendlyError extends AskError {
  userFriendly = true;
}