import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { cn } from "../../lib"
import { useClient } from "../RootProvider/RootProvider";
import { AskError } from "../../lib/core/errors";
import { Spin } from "../Spin/Spin";
import { TPublicResearch, TResearchState } from "../../lib/types";
import { Title } from "../Title";
import Icon from "../Icon";

import "./ResearchList.scss";


const b = cn("research-list");

export const ResearchList: React.FunctionComponent<TResearchListProps> = (props) => {
  const client = useClient();
  const [error, setError] = useState<AskError | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [researches, setResearches] = useState<TPublicResearch[]>([]);

  useEffect(() => {
    client.research.list()
      .then(setResearches)
      .catch(setError)
      .then(() => setLoading(false));
  }, []);


  const hasError = Boolean(error);
  const hasData = !isLoading && !hasError;

  return (
    <div className={b({}, [props.className])}>
      <Title size="large">
        History
      </Title>

      {isLoading && <Spin global />}

      {hasData &&
        <div className={b("list")}>
          {researches.map(research => research.task &&
            <Item {...research} key={research.id} />
          )}
        </div>
      }

      {hasError &&
        <div className={b("error")}>
          Oops, something went wrong...
        </div>
      }
    </div>
  );
}


const Item: React.FunctionComponent<TPublicResearch> = (props) =>
  <Link to={`/researches/${props.id}`} key={props.id} className={b("item")}>
    <Status type={props.state} />

    <div className={b("title")}>
      { props.task.question }
    </div>

    <div className={b("type")}>
      { props.type }
    </div>

    <div className={b("date")}>
      { formatDate(props.createdAt) }
    </div>
  </Link>;


const Status: React.FunctionComponent<TStatusProps> = (props) => {
  let type = "recent";
  if (props.type === "failed") {
    type = "failed";
  }

  if (props.type === "completed") {
    type = "completed";
  }

  return (
    <div className={b("status", { type })}>
      { type === "recent" && <Icon.Recent />}
      { type === "failed" && <Icon.Cross />}
      { type === "completed" && <Icon.Mark />}
    </div>
  );
}


// TODO: Fix this
function formatDate(value: number) {
  return new Date(value * 1000).toLocaleDateString(undefined, {
    month: "short",
    day: "numeric"
  });
}


type TResearchListProps = {
  className?: string;
}

type TStatusProps = {
  type: TResearchState;
}