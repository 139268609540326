export interface IPoint {
  x: number;
  y: number;
}

export interface ISize {
  width: number;
  height: number;
}

export interface IRect extends IPoint, ISize {}

export type IRectProp = keyof IRect;


export class Point implements IPoint {
  public readonly x: number;
  public readonly y: number;

  constructor({ x, y }: Partial<IPoint>) {
    this.x = x || 0;
    this.y = y || 0;
  }

  relativeTo(rect: IRect): Point {
    const r = new Rect(rect);
    return new Point({
      x: (this.x - r.x) / r.width,
      y: (this.y - r.y) / r.height,
    });
  }

  absoluteTo(rect: IRect): Point {
    const r = new Rect(rect);
    return new Point({
      x: (this.x * r.width) + r.x,
      y: (this.y * r.height) + r.y,
    })
  }

  /**
   * Returns whether two points are equal in x and y coordinates.
   *
   * @param rect The rectangle to compare this rectangle with.
   */
  equalTo({ x, y }: IPoint): boolean {
    return this.x === x && this.y === y;
  }

  /**
   * Returns whether a paint has zero x and y.
   */
  isZero(): boolean {
    return this.x === 0 && this.y === 0;
  }

  /**
   * Returns a plain object representation of a point.
   */
  asPlainObject(): IPoint {
    return {
      x: this.x,
      y: this.y,
    };
  }

  static zero(): Point {
    return new Point({ x: 0, y: 0 });
  }

  static fromEvent(event: IMouseEvent): Point {
    return new Point({
      x: event.clientX,
      y: event.clientY,
    });
  }
}


export class Rect implements IRect {
  public readonly x: number;
  public readonly y: number;
  public readonly width: number;
  public readonly height: number;

  constructor({ x, y, width, height }: Partial<IRect> = {}) {
    x = x || 0;
    y = y || 0;
    width = width || 0;
    height = height || 0;

    if (width < 0) {
      x = x + width;
      width = Math.abs(width);
    }

    if (height < 0) {
      y = y + height;
      height = Math.abs(height);
    }

    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  get minX(): number {
    return this.x;
  }

  get midX(): number {
    return this.x + Math.floor(this.width / 2);
  }

  get maxX(): number {
    return this.x + this.width;
  }

  get minY(): number {
    return this.y;
  }

  get midY(): number {
    return this.y + Math.floor(this.height / 2);
  }

  get maxY(): number {
    return this.y + this.height;
  }

  get origin(): IPoint {
    return {
      x: this.x,
      y: this.y,
    };
  }

  get size(): ISize {
    return {
      width: this.width,
      height: this.height,
    };
  }

  /**
   * Returns a rectangle which describes the view’s location
   * and size in its own coordinate system.
   */
  get bounds(): Rect {
    return new Rect(this.size);
  }

  /**
   * Returns a rectangle that is smaller or larger than the source
   * rectangle, with the same center point.
   */
  insetBy(dx: number, dy: number = 0): Rect {
    return Rect.zero();
  }

  /**
   * Returns a rectangle with an origin that is offset from
   * that of the source rectangle.
   */
  offsetBy(dx: number, dy: number = 0): Rect {
    return Rect.zero();
  }

  /**
   * Returns whether a rectangle contains a specified point.
   *
   * @param point The point to examine.
   */
  contains(point: IPoint): boolean {
    return point.x >= this.minX && point.x <= this.maxX
      && point.y >= this.minY && point.y <= this.maxY;
  }

  /**
   * Returns whether two rectangles are equal in size and position.
   *
   * @param rect The rectangle to compare this rectangle with.
   */
  equalTo(rect: IRect): boolean {
    const r2 = new Rect(rect);
    return this.x === r2.x && this.y === r2.y
      && this.width === r2.width && this.height === r2.height;
  }

  /**
   * Returns whether a rectangle has zero width, height and origin.
   */
  isZero(): boolean {
    return this.x === 0 && this.y === 0 && this.isEmpty();
  }

  /**
   * Returns whether a rectangle has zero width or height.
   */
  isEmpty(): boolean {
    return this.width === 0 || this.height === 0;
  }

  /**
   * Returns a plain object representation of a rectangle.
   */
  asPlainObject(): IRect {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
    };
  }

  static zero(): Rect {
    return new Rect();
  }
}

interface IMouseEvent {
  clientX: number;
  clientY: number;
}