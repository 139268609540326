import {Fragment} from "react";

import {Title} from "../../Title";


type TAuthorProps = {
  id: string;
  username: string;
}

export const Author: React.FunctionComponent<TAuthorProps> = ({id, username}) =>
  <Fragment>
    <Title size="small">Author</Title>

    <p>
      This research is created by @{username}
    </p>

    <p>
      <a href={`mailto:hello@ask-the-crowd.app?subject=Research #${id} Report`}>
        Report about the research
      </a>
    </p>
  </Fragment>;