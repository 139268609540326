import React, { Fragment } from "react";

import { cn } from "../../lib";
import { Error } from "..";
import { TModerationResearch } from "../../lib/types";

import { Row } from "./misc";
import "./Moderation.scss";


export const b = cn("moderation");

export abstract class Moderation<P extends TModerationProps, S extends TModerationState> extends React.Component<P, S> {
  get canSubmit(): boolean {
    return !this.props.isSubmitting;
  }

  renderWarn(): JSX.Element | null {
    const { state, moderator } = this.props.research;

    let warning = "";
    if (state === "waiting") {
      warning = "Not ready for moderation yet";
    }

    if (state === "completed") {
      warning = "Already moderated";
    }

    if (!warning) {
      return null;
    }

    return (
      <Row centered>
        <h1 className={b("title")}>
          {warning}
        </h1>

        {moderator && (
          <h2 className={b("moderator")}>
            {moderator}
          </h2>
        )}
      </Row>
    );
  }

  renderHead(): JSX.Element {
    return (
      <Row centered>
        <h2 className={b("subtitle")}>
          {this.props.research.task.question}
        </h2>
      </Row>
    );
  }

  renderBody(): JSX.Element {
    return <Fragment />;
  }

  renderError(): JSX.Element | null {
    const {error} = this.props;
    if (!error) {
      return null;
    }

    return (
      <Row centered>
        <Error value={error} embed />
      </Row>
    )
  }

  renderFoot(): JSX.Element {
    return <Fragment />;
  }

  renderContent() {
    const {research} = this.props;
    if (research.state === "deleted") {
      return (
        <div className={b("wait")}>
          Sorry, but the author deleted his account<br />with this research
        </div>
      );
    }

    if (research.state === "failed") {
      return (
        <div className={b("wait")}>
          An error has occurred. Please create a new research.
        </div>
      );
    }

    return (
      <Fragment>
        { this.renderWarn() }
        { this.renderHead() }
        { this.renderBody() }
        { this.renderError() }
        { this.renderFoot() }
      </Fragment>
    )
  }

  render() {
    return (
      <div className={b()}>
        {this.renderContent()}
      </div>
    );
  }
}


export type TModerationProps = {
  research: TModerationResearch;

  error: Error | null;
  isSubmitting: boolean;

  onSubmit(badIds: number[]): void;
}

export type TModerationState = {

}