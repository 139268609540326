import React from "react";

import {Button} from "../../Button";

import {b} from "../AuthForm";


export const Switcher: React.FunctionComponent<TSwitcherProps> = (props) =>
  <Button className={b("switch")} onClick={props.onSwitch}>
    {props.signin ? "Sign up" : "Sign in"}
  </Button>


type TSwitcherProps = {
  signin: boolean;

  onSwitch: () => void;
}