import { Fragment } from "react";

import { TImage } from "../../../lib/types";
import { ImageUploader } from "../../ImageUploader/ImageUploader";
import { Input } from "../../Input";
import { b, NewResearchForm, TNewResearchFormProps, TNewResearchFormState } from "../NewResearchForm";


export class NewCommonResearchForm extends NewResearchForm<TNewCommonResearchFormProps, TNewCommonResearchFormState> {
  constructor(props: TNewCommonResearchFormProps) {
    super(props);

    this.state = {
      file: null,
      meta: null,
      question: "",

      shaking: {
        image: false,
        question: false,
      },
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  validate(): boolean {
    const isQuestionValid = this.isQuestionValid;
    const isImageValid = this.props.type !== "heatmap" || Boolean(this.state.file);

    this.setState({
      shaking: {
        image: !isImageValid,
        question: !isQuestionValid,
      },
    });

    return isQuestionValid && isImageValid;
  }

  onSubmit() {
    let image: [File, TImage] | null = null;

    const { file, meta } = this.state;
    if (file && meta) {
      image = [file, meta];
    }

    this.validate() && this.props.onSubmit({
      question: this.state.question,
      image: image,
    });
  }

  setShaking(name: keyof TShakingAnimation, value: boolean) {
    this.setState({
      shaking: Object.assign(this.state.shaking, {
        [name]: value,
      }),
    });
  }

  onFileChange(file: File, meta: TImage) {
    this.setState({ file, meta });
  }

  renderForm(): JSX.Element {
    const stopShaking = (name: keyof TShakingAnimation) =>
      () => this.setShaking(name, false);

    const { shaking } = this.state;

    return (
      <Fragment>
        <Input
          shake={shaking.question}
          placeholder={this.props.placeholder}
          onChange={this.onQuestionChange}
          onAnimationEnd={stopShaking("question")}
        />

        <div className={b("area", { shake: shaking.image }, ["row"])} onAnimationEnd={stopShaking("image")}>
          <ImageUploader onFile={this.onFileChange} />
        </div>
      </Fragment>
    );
  }
}

type TNewCommonResearchFormProps = TNewResearchFormProps & {
  type: "question" | "heatmap";

  onSubmit: (task: TCommonResearchInput) => void;
}

export type TCommonResearchInput = {
  question: string;
  image: [File, TImage] | null;
}

type TNewCommonResearchFormState = TNewResearchFormState & {
  file: File | null;
  meta: TImage | null;
  question: string;
  shaking: TShakingAnimation;
}

type TShakingAnimation = {
  image: boolean;
  question: boolean;
}