export function normalize(value: string) {
  return (value || "").toString().toLowerCase().trim()
}


export function isLetter(value: string) {
  value = (value || "").toString();
  return value && value.toLocaleLowerCase() !== value.toLocaleUpperCase();
}


export function isEnglishLetter(value: string) {
  value = (value || "").toLocaleLowerCase();
  return value.length === 1 && isLetter(value)
    && value.charCodeAt(0) >= "a".charCodeAt(0)
    && value.charCodeAt(0) <= "z".charCodeAt(0);
}


export function trimPrefix(value: string, prefix: string): string {
  if (value.startsWith(prefix)) {
    return value.slice(1);
  }

  return value;
}


export function trimSuffix(value: string, suffix: string): string {
  if (value.endsWith(suffix)) {
    return value.slice(0, -1);
  }

  return value;
}


export function hasPrefix(value: string, prefix: string): boolean {
  return value.startsWith(prefix);
}


export function hasSuffix(value: string, suffix: string): boolean {
  return value.startsWith(suffix);
}