import React from "react";

import { cn } from "../../lib";
import { IRect, Point, Rect } from "../../lib/geom";
import { useFrame } from "../AspectRatioArea/AspectRatioArea";

import { TSelectionAreaProps } from ".";
import "./SelectionArea.scss";


const block = cn("selection-area");

export const SelectionArea: React.FunctionComponent<TSelectionAreaProps> = (props) => {
  const frame = new Rect(useFrame());

  let [isDrawing, setDrawing] = React.useState<boolean>(false);
  let [startPoint, setStartPoint] = React.useState<Point>(Point.zero());
  let [endPoint, setEndPoint] = React.useState<Point>(Point.zero());

  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  });

  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.buttons !== 1) {
      return;
    }

    setDrawing(true);
    setStartPoint(Point.fromEvent(event));
  };

  const handleMouseMove = (event: MouseEvent) =>
    isDrawing && setEndPoint(Point.fromEvent(event));

  const handleMouseUp = (event: MouseEvent) => {
    setDrawing(false);

    if (startPoint.isZero() || endPoint.isZero()) {
      return;
    }

    let rect = buildRect(frame, startPoint, endPoint);

    setStartPoint(Point.zero());
    setEndPoint(Point.zero());

    if (rect.width < 36 || rect.height < 36) {
      return;
    }

    if (rect && props.onSelection) {
      props.onSelection({
        x: rect.x / frame.width,
        y: rect.y / frame.height,
        width: rect.width / frame.width,
        height: rect.height / frame.height,
      });
    }
  };

  const rect = buildRect(frame, startPoint, endPoint) || {};
  const style = {
    left: rect.x,
    top: rect.y,
    width: rect.width,
    height: rect.height,
  };

  const hasSelection = isDrawing && rect;

  return (
    <div className={block()} onMouseDown={handleMouseDown}>
      {hasSelection && (
        <div className={block("selection")} style={style}></div>
      )}

      {props.children}
    </div>
  );
}

function buildRect(frame: Rect, ss: Point, se: Point): IRect {
  if (frame.isEmpty() || ss.isZero() || se.isZero()) {
    return Rect.zero();
  }

  let x = Math.max(Math.min(ss.x, se.x), frame.x);
  let y = Math.max(Math.min(ss.y, se.y), frame.y);
  let w = Math.min(Math.max(ss.x, se.x) - x, (frame.x + frame.width) - x);
  let h = Math.min(Math.max(ss.y, se.y) - y, (frame.y + frame.height) - y);

  return {
    x: x - frame.x,
    y: y - frame.y,
    width: w,
    height: h,
  };
}
