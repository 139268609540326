import { IRect } from "./geom";

export type TAuthToken = string;
export type TSubmitssionToken = string;

export type TUserRole = "user" | "moderator" | "admin";
export type TResearchState = "waiting" | "moderation" | "completed" | "deleted" | "failed";

export const ResearchType = {
  question: "question",
  heatmap: "heatmap",
  sideBySide: "side-by-side"
} as const

export type TResearchType = typeof ResearchType[keyof typeof ResearchType];

interface ICommonResearch {
  id: string;
  type: TResearchType;
  state: TResearchState;
  createdAt: number;
}

interface IPublicResearch extends ICommonResearch {
  username: string;
  meta?: any;
}

interface IModerationResearch extends ICommonResearch {
  moderator: string;
}

export type TQuestionResearchResponse = IResearchResponse<IQuestionResearchResponseData>;
export type THeatmapResearchResponse = IResearchResponse<IHeatmapResearchResponseData>;
export type TSideBySideResearchResponse = IResearchResponse<ISideBySideResearchResponseData>;

interface IQuestionResearch extends IPublicResearch {
  type: "question";
  task: IQuestionTaskData;
  meta?: TQuestionResearchMeta;
  responses: TQuestionResearchResponse[];
}

export type TQuestionResearchMeta = Record<string, string[]>;
export type THeatmapResearchMeta = IRect[];

interface IHeatmapResearch extends IPublicResearch {
  type: "heatmap";
  task: IHeatmapTaskData;
  meta?: THeatmapResearchMeta;
  responses: THeatmapResearchResponse[];
}

interface ISideBySideResearch extends IPublicResearch {
  type: "side-by-side";
  task: ISideBySideTaskData;
  responses: TSideBySideResearchResponse[];
}

export type TQuestionResearch = IQuestionResearch;
export type THeatmapResearch = IHeatmapResearch;
export type TSideBySideResearch = ISideBySideResearch;

export type TQuestionModerationResearch = IQuestionResearch & IModerationResearch;
export type THeatmapModerationResearch = IHeatmapResearch & IModerationResearch;
export type TSideBySideModerationResearch = ISideBySideResearch & IModerationResearch;

export type TPublicResearch = TQuestionResearch | THeatmapResearch | TSideBySideResearch;
export type TModerationResearch = TQuestionModerationResearch | THeatmapModerationResearch | TSideBySideModerationResearch;

export interface IResearchResponse<T = unknown> {
  id: number;
  data: T;
  approved?: boolean;
}

export interface IQuestionResearchResponseData {
  text: string;
}

export interface IHeatmapResearchResponseData {
  x: number;
  y: number;
  t: number;
}

export interface ISideBySideResearchResponseData {
  difference: string;
  whyBetter: string;
  whichBetter: "a" | "b";
}

interface ITask<T> {
  type: TResearchType;
  data: T;
}

interface IQuestionTask extends ITask<IQuestionTaskData> {
  type: "question";
}

interface IHeatmapTask extends ITask<IHeatmapTaskData> {
  type: "heatmap";
}

interface ISideBySideTask extends ITask<ISideBySideTaskData> {
  type: "side-by-side";
}

interface IBasicTaskData {
  question: string;
}

export interface IQuestionTaskData extends IBasicTaskData {
  image?: ITaskImage;
}

export interface IHeatmapTaskData extends IBasicTaskData {
  image: ITaskImage;
}

export interface ISideBySideTaskData extends IBasicTaskData {
  a: ITaskImage;
  b: ITaskImage;
}

export type TTask = IQuestionTask | IHeatmapTask | ISideBySideTask;
export type TTaskData = IQuestionTaskData | IHeatmapTaskData | ISideBySideTaskData;

export interface IImageMeta {
  url: string;
  width: number;
  height: number;
}

export interface ITaskImage extends IImageMeta {
  scale: number;
}

export interface IBasicUserInfo {
  email: string;
  username: string;
  isTermsAccepted: boolean;
  isCookiesAccepted: boolean;
  keepSignedIn: boolean;
}

export interface IUserInfo extends IBasicUserInfo {
  id: number;
  role: TUserRole;
}

export interface ICommonTask {
  question: string;
  image: ITaskImage | null;
}


export type TSignupTerms = {
  isTermsAccepted: boolean;
  isCookiesAccepted: boolean;
  keepSignedIn: boolean;
};

export type TSignupRequest = TSignupTerms & {
  email: string;
  promo: string;
};

export type TUserRequiredFields = {
  id: number;
}

export type TUserEditableFields = {
  email: string;
  username: string;
  isTermsAccepted: boolean;
  isCookiesAccepted: boolean;
  keepSignedIn: boolean;
}

export type TUserInfo = TUserRequiredFields & TUserEditableFields;

export type TImage = {
  url: string;
  scale: number;
  width: number;
  height: number;
}

export const enum ETaskType {
  Question = "question",
  Heatmap = "heatmap",
  SideBySide = "side-by-side",
}

export interface ITemplate {
  id: number;
  type: TResearchType;
  soon: boolean;
  title: string;
  question: string;
  description: string;
}

export type TAdminResearch = {
  id: string;
  type: string;
  state: string;
  author: string;
  moderator: string;
  question: string;
  moderationStartedAt: number;
  moderationCompletedAt: number;
}
