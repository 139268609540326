import { cn } from "../../lib";
import { Rect } from "../../lib/geom";
import { useFrame } from "../AspectRatioArea/AspectRatioArea";

import { TSelectionItemProps } from ".";
import "./SelectionItem.scss";


const b = cn("selection-item");

export const SelectionItem: React.FunctionComponent<TSelectionItemProps> = (props) => {
  const frame = new Rect(useFrame());
  if (frame.isEmpty()) {
    return null;
  }

  const {x, y, width, height} = props.frame;
  const style = {
    left: x * frame.width,
    top: y * frame.height,
    width: width * frame.width,
    height: height * frame.height,
  };

  const handleMouseDown = (event: React.MouseEvent) =>
    event.stopPropagation();

  return (
    <div className={b({view: !props.deletable})} style={style} onMouseDown={handleMouseDown}>
      {props.value && (
        <div className={b("value")}>
          {props.value}
        </div>
      )}

      <div className={b("controls")}>
        {props.deletable && (
          <div className={b("button", {type: "remove"})} onClick={props.onDelete}>
            Remove
          </div>
        )}

        <div className={b("button", {type: "cancel"})} onClick={props.onClose}>
          {props.deletable ? "Cancel" : "Close"}
        </div>
      </div>
    </div>
  );
}
