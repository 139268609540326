import { cn } from "../../lib";

import { Row } from "./Histogram.Row";
import "./Histogram.scss";


export const b = cn("histogram");

export const Histogram: React.FunctionComponent<THistogramProps> = (props) => {
  const total = props.total;
  const items = props.items;

  const chart = items.map(item => ({
    label: item.label,
    count: item.count,
    value: total ? Math.round(item.count / total * 100) : 0,
  }));

  return (
    <div className={b()}>
      {chart.map(row => <Row key={row.label} {...row} />)}
    </div>
  );
}


type THistogramProps = {
  total: number;
  items: THistogramItem[];
};

type THistogramItem = {
  label: string;
  count: number;
}