import { cn } from "../../lib";
import { Button } from "../Button";
import { Input } from "../Input";
import Icon from "../Icon";

import "./SubmitableInput.scss";
import { Spin } from "..";
import React from "react";


const b = cn("submitable-input");

export const SubmitableInput: React.FunctionComponent<TSubmitableInputProps> = (props) => {
  const [value, setValue] = React.useState(String(props.value || ""));
  const {view, disabled, submitting, onSubmit, ...rest} = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    props.onChange && props.onChange(event);
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) =>
    event.key === "Enter" && onSubmit && onSubmit(value);

  const handleSubmit = () =>
    onSubmit && onSubmit(value);

  return (
    <div className={b({ disabled })}>
      <Input
        {...rest}
        view="round"
        value={value}
        className={b("input")}
        disabled={disabled || submitting}
        onChange={handleChange}
        onKeyPress={handleEnter}
      />

      <Button className={b("button")} onClick={handleSubmit}>
        {submitting &&
          <Spin size="xs" theme="contrast" />
        }

        {submitting ||
          <Icon.Arrow width={16} height={9} />
        }
      </Button>
    </div>
  );
}


SubmitableInput.defaultProps = {
  submitting: false,
  onSubmit: () => {},
}


type TSubmitableInputProps = React.ComponentProps<typeof Input> & {
  submitting?: boolean;

  onSubmit?: (value: string) => void;
}