import React from "react";

import {AskError} from "../../../lib/core/errors";
import {useAuth} from "../../../lib/hooks";
import {useClient} from "../../RootProvider";
import {Error} from "../../Error";

import {CodeForm} from "../forms/CodeForm";
import {Head} from "../misc/Head";
import {Body} from "../misc/Body";
import {Foot} from "../misc/Foot";
import {Step} from "../misc/Step";


export const StepCode: React.FunctionComponent<TStepCodeProps> = (props) => {
  const auth = useAuth();
  const client = useClient();

  const [error, setError] = React.useState<AskError | null>(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const hasError = Boolean(error);

  const handleSubmit = (code: string) => {
    setSubmitting(true);
    setError(null);

    client.user.submitCode(code)
      .then(auth.authenticate)
      .then(props.onComplete)
      .catch(setError)
      .then(() => setSubmitting(false));
  }

  return (
    <Step name="code">
      <Head />

      <Body>
        <CodeForm title="We sent a code" submitting={isSubmitting} onSubmit={handleSubmit}>
          {hasError &&
            <Error value={error!} embed />
          }

          {hasError ||
            <p>Follow the link that was sent to your email or enter the code manually.</p>
          }
        </CodeForm>

        <Foot>
          {props.switcher}
        </Foot>
      </Body>
    </Step>
  )
}


type TStepCodeProps = {
  switcher: JSX.Element;

  onComplete: () => void;
}