import { Outlet } from "react-router";

import { cn } from "../../lib";

import "./CasePage.scss";


const b = cn("case-page");

export const CasePage: React.FunctionComponent = (props) =>
  <div className={b()}><Outlet /></div>;


export const Wrap: React.FunctionComponent = (props) =>
  <div className={b("wrap")}>{ props.children }</div>;