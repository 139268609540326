import React from "react";

import {Title} from "../../Title";
import {SubmitableInput} from "../../SubmitableInput";

import {b} from "../AuthForm";


export const CodeForm: React.FunctionComponent<TCodeFormProps> = (props) => {
  const [shake, setShake] = React.useState(false)

  const handleSubmit = (code: string) => {
    code.length ? props.onSubmit(code) : setShake(true);
  }

  return (
    <div className={b("form")}>
      <Title size="large">
        {props.title}
      </Title>

      <SubmitableInput
        shake={shake}
        submitting={props.submitting}
        placeholder="XXXXXX"
        onSubmit={handleSubmit}
        onAnimationEnd={() => setShake(false)}
      />

      {props.children}
    </div>
  );
}


type TCodeFormProps = {
  title: string;
  submitting: boolean;
  onSubmit: (code: string) => void;
}