import React from "react";

import {AskError, UserFriendlyError} from "../../../lib/core/errors";
import {useClient} from "../../RootProvider";
import {Error} from "../../Error";
import {NotFoundError} from "../../../lib/api/errors";

import {b} from "../AuthForm";
import {EmailForm} from "../forms/EmailForm";
import {Body} from "../misc/Body";
import {Foot} from "../misc/Foot";
import {Head} from "../misc/Head";
import {Step} from "../misc/Step";


export const StepSignin: React.FunctionComponent<TStepSigninProps> = (props) => {
  const client = useClient();

  const [error, setError] = React.useState<AskError | null>(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const hasError = Boolean(error);

  const handleError = (error: AskError) => {
    if (error instanceof NotFoundError) {
      error = new UserFriendlyError("Email not found");
    }

    setSubmitting(false);
    setError(error);
  }

  const handleSubmit = (email: string) => {
    setSubmitting(true);
    setError(null);

    client.user.requestCode(email)
      .then(props.onComplete)
      .catch(handleError)
  };

  return (
    <Step name="email">
      <Head />

      <Body>
        <EmailForm title="Sign in" submitting={isSubmitting} onSubmit={handleSubmit}>
          {hasError &&
            <Error value={error!} className={b("error")} embed />
          }

          {hasError ||
            <p>We will send an email with a link and the code to log in.</p>
          }
        </EmailForm>

        <Foot>
          {props.switcher}
        </Foot>
      </Body>
    </Step>
  )
}

type TStepSigninProps = {
  switcher: JSX.Element;

  onComplete: () => void;
}