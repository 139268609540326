import React from "react";

import { cn } from "../../lib"

import "./Button.scss";


const b = cn("button");

export const Button: React.FunctionComponent<TButtonProps> = ({ type, view, children, className, ...props }) =>
  <button className={b({ view }, [className])} type={type} {...props}>
    { children }
  </button>


type TButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  view?: "action";
};


export const ConfirmationButton: React.FunctionComponent<TConfirmationButtonProps> = ({ confirm, children, onClick, ...rest }) => {
  const [isClicked, setClicked] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (!isClicked) {
      return setClicked(true);
    }

    onClick && onClick(event);
  }

  return (
    <Button {...rest} onClick={handleClick}>
      {isClicked ? confirm : children}
    </Button>
  );
}

type TConfirmationButtonProps = TButtonProps & {
  confirm: JSX.Element | string;
}