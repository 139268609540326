import React, { Fragment } from "react";

import { useClient } from "../../components/RootProvider/RootProvider";
import { Researches as ResearchList } from "../../components/admin/Researches";
import { useRequest } from "../../lib/hooks";


export const Moderation: React.FunctionComponent = (props) => {
  const client = useClient();
  const request = useRequest(() => client.admin.researches("moderation"));

  const handleReject = (id: string) => {
    client.admin.reject(id)
      .then(request.refresh)
      .catch(alert);
  };

  return (
    <div className="page__content">
      {request.isLoading &&
        <h1>Loading...</h1>
      }

      {request.hasError &&
        <Fragment>
          <h1>Loading error</h1>
          <p>{request.error}</p>
        </Fragment>
      }

      {request.hasData &&
        <ResearchList items={request.data} onReject={handleReject} />
      }
    </div>
  );
};