import React, { Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAuth, useId, useRequest } from "../../lib/hooks";
import { useClient } from "../RootProvider";
import { Spin, Error } from "..";
import { ResearchType, TModerationResearch } from "../../lib/types";
import { ModerationQuestion, ModerationHeatmap, ModerationSideBySide } from "../Moderation";


type TModerationControllerProps = {
  id: string;
};

export const ModerationController: React.FunctionComponent<TModerationControllerProps> = (props) => {
  const client = useClient();
  const navigate = useNavigate();

  const id = useId();
  const auth = useAuth();
  const request = useRequest(() => client.moderation.fetch(id!));

  const [error, setError] = React.useState<Error | null>(null);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const handleCleanup = (badIds: number[]) => {
    if (isSubmitting) {
      return;
    }

    setSubmitting(true);
    setError(null);

    client.moderation.cleanup(id!, badIds)
      .then(() =>
        navigate(`/moderation/researches/${id}/success`))
      .catch(setError)
      .then(() => setSubmitting(false));
  };

  const isAllowed = auth.user && ["admin", "moderator"].includes(auth.user.role);
  if (!isAllowed) {
    return <Navigate to="/" />;
  }

  return (
    <Fragment>
      {request.isLoading &&
        <Spin size="l" global />
      }

      {request.hasError &&
        <Error value={request.error} />
      }

      {request.hasData &&
        researchFactory({
          research: request.data,

          error: error,
          isSubmitting: isSubmitting,

          onSubmit: handleCleanup,
        })
      }
    </Fragment>
  )
}

type TFactoryOptions = {
  research: TModerationResearch;

  error: Error | null;
  isSubmitting: boolean;

  onSubmit: (badIds: number[]) => void;
}

function researchFactory(options: TFactoryOptions): JSX.Element | null {
  const {research, ...props} = options;

  if (research.type === ResearchType.question) {
    return <ModerationQuestion research={research} {...props} />;
  }

  if (research.type === ResearchType.heatmap) {
    return <ModerationHeatmap research={research} {...props} />;
  }

  if (research.type === ResearchType.sideBySide) {
    return <ModerationSideBySide research={research} {...props} />;
  }

  return null;
}