import { Link } from "react-router-dom";
import { cn } from "../../../lib";
import { TAdminResearch } from "../../../lib/types";

import "./Researches.scss";


const b = cn("researches-admin");

type TProps = {
  items: TAdminResearch[];
  className?: string;

  onReject: (id: string) => void;
}

export const Researches: React.FunctionComponent<TProps> = (props) => {
  const items = props.items
    .map(item => ({
      id: item.id,
      question: item.question,
      moderator: item.moderator,
      startedTime: new Date(item.moderationStartedAt * 1000),
      awaitingTime: formatTime(item.moderationStartedAt),
    }))
    .sort((a, b) => b.awaitingTime - a.awaitingTime);

  return (
    <div className={b()}>
      {items.map(item =>
        <div key={item.id} className={b("item")}>
          <div className={b("item-column", { type: "question" })}>
            <Link to={`/moderation/researches/${item.id}`}>
              {item.question}
            </Link>

            {item.moderator && ` (${item.moderator})`}
          </div>
          <div className={b("item-column")}>
            <span className={b("await")} title={String(item.startedTime)}>
              {item.awaitingTime} min ago
            </span>
          </div>
          <div className={b("item-column")}>
            <button onClick={() => props.onReject(item.id)}>Reject</button>
          </div>
        </div>
      )}
    </div>
  );
};

function formatTime(value: number): number {
  return Math.round(((Date.now() / 1000) - value) / 60);
}