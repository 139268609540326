import React, { Fragment } from "react";

import {Button} from "../../Button";
import {Checkbox} from "../../Checkbox";
import {Title} from "../../Title";
import {Spin} from "../../Spin";

import {b, TTermsState} from "../AuthForm";
import {Foot} from "../misc/Foot";


export const TermsForm: React.FunctionComponent<TTermsFormProps> = (props) => {
  const [terms, setTerms] = React.useState<TTermsState>({
    isTermsAccepted: false,
    isCookiesAccepted: false,
    keepSignedIn: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name as keyof TTermsState;
    setTerms(Object.assign({}, terms, {
      [name]: event.currentTarget.checked,
    }));
  };

  const handleSubmit = () => {
    props.onSubmit(terms);
  }

  const {submitting} = props;
  const isDisabled = submitting || !terms.isTermsAccepted;

  return (
    <Fragment>
      <div className={b("form")}>
        <Title size="large">
          {props.title}
        </Title>

        <Checkbox checked={terms.isTermsAccepted} name="isTermsAccepted" onChange={handleChange}>
          I do accept <a href="/terms" target="_blank" rel="noreferrer">terms of use</a> (required)
        </Checkbox>

        <Checkbox checked={terms.keepSignedIn} name="keepSignedIn" onChange={handleChange}>
          Keep me signed in
        </Checkbox>

        <Checkbox checked={terms.isCookiesAccepted} name="isCookiesAccepted" onChange={handleChange}>
          I accept to set cookies for marketing, analytics and other purposes. It will help us to create a better product with a resonable price.
        </Checkbox>
      </div>

      <Foot>
        {props.children}

        <Button view="action" onClick={handleSubmit} disabled={isDisabled}>
          {submitting && <Spin size="s" />}
          {submitting || "Complete"}
        </Button>
      </Foot>
    </Fragment>
  );
}


type TTermsFormProps = {
  title: string;
  submitting: boolean;

  onSubmit: (terms: TTermsState) => void;
}