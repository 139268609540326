import React from "react";

import { cn } from "../../lib"
import { Fade } from "../Fade";
import { Menu } from "../Menu"
import { Title } from "../Title";
import Icon from "../Icon";

import "./SandwichMenu.scss";


const b = cn("sandwich-menu");

const SandwichMenu: React.FunctionComponent<TSanswichMenuProps> = (props) => {
  const [isMenuOpened, setMenuOpened] = React.useState<boolean>(false);

  const handleToggle = () =>
    setMenuOpened(!isMenuOpened);

  return (
    <div className={b()} onClick={handleToggle}>
      <Title size="medium" className={b("title")}>
        { props.title }
      </Title>

      <Icon.Arrow className={b("arrow")} />

      <Fade if={isMenuOpened} onClick={handleToggle}>
        <Menu className={b("menu")} layout="vertical">
          { props.children }
        </Menu>
      </Fade>
    </div>
  );
}

const Item: React.FunctionComponent = (props) =>
  <Menu.Item>{ props.children }</Menu.Item>


type TSanswichMenuProps = {
  title: string;
}


export default Object.assign(SandwichMenu, { Item });