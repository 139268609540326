import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useClient } from "../RootProvider";
import { useRequest, useId } from "../../lib/hooks";
import { ResearchType, TSubmitssionToken, TTask } from "../../lib/types";
import { Error } from "../Error";
import { Wrap } from "../../pages/CasePage";
import { HeatmapCase, QuestionCase, SideBySideCase } from "../Case";
import { IAPIClient } from "../../lib/api";
import { Spin } from "..";


export const CaseController: React.FunctionComponent = () => {
  const client = useClient();
  const navigate = useNavigate();

  const id = useId();
  const request = useRequest(() => client.task.fetch(id!));

  const onComplete = (token: TSubmitssionToken) =>
    navigate(`/cases/${id}/success`, {
      state: { token }
    });

  return (
    <Fragment>
      {request.isLoading && (
        <Spin size="l" global />
      )}

      {request.hasError && (
        <Wrap>
          <Error value={request.error} />
        </Wrap>
      )}

      {request.hasData &&
        caseFactory(id!, request.data, client, onComplete)
      }
    </Fragment>
  );
}


function caseFactory(id: string, task: TTask, client: IAPIClient, onComplete: (token: TSubmitssionToken) => void): JSX.Element | null {
  if (task.type === ResearchType.question) {
    return <QuestionCase id={id} data={task.data} client={client} onComplete={onComplete} />
  }

  if (task.type === ResearchType.heatmap) {
    return <HeatmapCase id={id} data={task.data} client={client} onComplete={onComplete} />
  }

  if (task.type === ResearchType.sideBySide) {
    return <SideBySideCase id={id} data={task.data} client={client} onComplete={onComplete} />
  }

  // TODO: Show error
  return null;
}