import React from "react";

import { cn } from "../../lib";
import { IRect, Point, Rect } from "../../lib/geom";
import { useClient } from "../RootProvider";
import { ResearchType, THeatmapResearch, TPublicResearch, TQuestionResearch } from "../../lib/types";

import {
  IGroupStatisticProps,
  IGroupStatisticItemProps,
  IThumbProps,
  IStatsProps,
  IResearchStats,
  TStats
} from "./GroupStatistic.types";
import "./GroupStatistic.scss";


const block = cn("group-statistic");

export const GroupStatistic: React.FunctionComponent<IGroupStatisticProps> = (props) => {
  const client = useClient();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [researches, setResearches] = React.useState<IResearchStats[]>([]);

  React.useEffect(() => {
    setLoading(true);

    Promise.all(props.ids.map(id => client.research.fetch(id)))
      .then(computeResearchStatistics)
      .then(setResearches)
      .then(() => setLoading(false))
      .catch(console.error);
  }, [props.ids]);

  if (!props.ids.length) {
    return null;
  }

  if (!isLoading && !researches.length) {
    return null;
  }

  return (
    <div className={block()}>
      {isLoading && "Loading..."}

      {isLoading || researches.map(research =>
        <GroupStatisticItem research={research} key={research.id} />
      )}
    </div>
  );
};

const GroupStatisticItem: React.FunctionComponent<IGroupStatisticItemProps> = (props) => {
  const {research} = props;

  return (
    <a className={block("item")} href={`/researches/${research.id}`} target="_blank" rel="noreferrer">
      <div className={block("container")}>
        {research.image && (
          <Thumb src={research.image} />
        )}

        <div className={block("content")}>
          <div className={block("question")}>
            { research.question }
          </div>

          <Stats data={research.stats} />
        </div>
      </div>
    </a>
  );
}

const Thumb: React.FunctionComponent<IThumbProps> = (props) => {
  const style: React.CSSProperties = {
    backgroundImage: `url(${props.src})`,
  };

  return (
    <div className={block("thumb")} style={style} />
  );
}

const Stats: React.FunctionComponent<IStatsProps> = (props) => {
  const stats = Object.keys(props.data).map(tag => ({
    tag: tag,
    value: props.data[tag],
  }));

  return (
    <div className={block("stats")}>
      {stats.map(item =>
        <div className={block("stats-item")} key={item.tag}>
          <div className={block("stats-item-tag")}>
            { item.tag }
          </div>
          <div className={block("stats-item-value")} style={{ width: `${item.value}%` }} />
          <div className={block("stats-item-percent")}>
            { item.value }%
          </div>
        </div>
      )}
    </div>
  );
};


function computeResearchStatistics(researches: TPublicResearch[]): IResearchStats[] {
  const researchesStats: IResearchStats[] = [];
  researches.forEach(research => {
    let stats: TStats | null = null;

    if (research.type === ResearchType.question) {
      stats = computeQuestionStatistics(research);
    }

    if (research.type === ResearchType.heatmap) {
      stats = computeHeatmapStatistics(research);
    }

    if (research.type === ResearchType.sideBySide) {
      return {a: 0, b: 0};
    }

    if (!stats) {
      return {a: 0, b: 0};
    }

    researchesStats.push({
      id: research.id,
      stats: stats,
      image: research.task.image?.url,
      question: research.task.question,
    });
  });

  return researchesStats.sort((a, b) => {
    return b.stats["a"] - a.stats["a"];
  });
}


function computeQuestionStatistics(research: TQuestionResearch): TStats | null {
  const {meta} = research;
  if (!meta) {
    return null;
  }

  const stats: TStats = {};
  Object.keys(meta).forEach((id: string) => {
    meta[id].forEach(tag => {
      stats[tag] = (stats[tag] || 0) + 1;
    })
  });

  let total = 0;
  Object.values(stats).forEach(count => {
    total += count;
  });

  const percents: TStats = {};
  Object.keys(stats).sort().forEach(tag => {
    percents[tag] = Math.round(stats[tag] * 100 / total);
  });

  return percents;
}


function computeHeatmapStatistics(research: THeatmapResearch): TStats | null {
  const {meta} = research;
  if (!Array.isArray(meta) || !meta.length) {
    return null;
  }

  const points: Point[] = research.responses.map(response => {
    return new Point(response.data);
  });

  const stats: TStats = {};
  meta.forEach((selection: IRect, index: number) => {
    const tag = String.fromCharCode(("a".charCodeAt(0) + index));
    stats[tag] = 0;

    const frame = new Rect(selection);
    points.forEach(point =>
      frame.contains(point) && (stats[tag] += 1));
  });

  const percents: TStats = {};
  Object.keys(stats).sort().forEach(tag => {
    percents[tag] = Math.round(stats[tag] * 100 / points.length);
  });

  return percents;
}