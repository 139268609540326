import React from "react";

import {AskError} from "../../../lib/core/errors";
import {NotFoundError} from "../../../lib/api/errors";
import {useClient} from "../../RootProvider";
import {Error} from "../../Error";

import {b} from "../AuthForm";
import {EmailForm} from "../forms/EmailForm";
import {Body} from "../misc/Body";
import {Foot} from "../misc/Foot";
import {Head} from "../misc/Head";
import {Step} from "../misc/Step";


export const StepSignup: React.FunctionComponent<TStepSignupProps> = (props) => {
  const client = useClient();

  const [error, setError] = React.useState<AskError | null>(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const hasError = Boolean(error);

  const handleSubmit = (email: string) => {
    setSubmitting(true);
    setError(null);

    client.user.requestCode(email)
      .then(props.onUserExists)
      .catch((error) => {
        error instanceof NotFoundError
          ? props.onComplete(email)
          : setError(error);
      })
      .then(() => setSubmitting(false));
  };

  return (
    <Step name="email">
      <Head />

      <Body>
        <EmailForm title="Sign up" submitting={isSubmitting} onSubmit={handleSubmit}>
          {hasError &&
            <Error value={error!} className={b("error")} embed />
          }

          {hasError ||
            <p>You will be able to create a few researches for free.</p>
          }
        </EmailForm>

        <Foot>
          {props.switcher}
        </Foot>
      </Body>
    </Step>
  )
}

type TStepSignupProps = {
  switcher: JSX.Element;

  onComplete: (email: string) => void;
  onUserExists: () => void;
}