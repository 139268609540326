export const EMAIL_REGEXP = /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line no-useless-escape


export function isLooksLikeEmail(value: string): boolean {
  return EMAIL_REGEXP.test(value);
}


export function isObject(value: unknown): boolean {
  return Boolean(value) && typeOf(value) === "Object";
}


export function typeOf(target: any): string {
  return Object.prototype.toString.call(target).slice(8, -1);
}


export function hasOwnProperty(target: any, property: string): boolean {
  return Object.prototype.hasOwnProperty.call(target, property);
}


export function isFalse(value: any): boolean {
  return value === false;
}


export function isFalsy(value: any): boolean {
  return !value;
}


export function isTruthy(value: any): boolean {
  return Boolean(value);
}


export function isInstanceOf(target: any, klass: any): boolean {
  return target instanceof klass;
}