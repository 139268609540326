import React from "react";

import { IAPIClient } from "../../lib/api";
import { UserFriendlyError } from "../../lib/core/errors";
import { ICommonTask, IImageMeta, ISideBySideTaskData, ITemplate, ResearchType, TPublicResearch, TResearchType } from "../../lib/types";
import { NewCommonResearchForm, NewSideBySideResearchForm } from "../NewResearchForm";
import { TCommonResearchInput } from "../NewResearchForm/types/Common";
import { TSideBySideResearchInput } from "../NewResearchForm/types/SideBySide";


export const NewResearchController: React.FunctionComponent<TNewResearchControllerProps> = (props) => {
  const [error, setError] = React.useState<Error | null>(null);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const { id, type, title, question: placeholder } = props.template;

  const handleSubmit = (taskData: TCommonResearchInput | TSideBySideResearchInput) => {
    setSubmitting(true);

    createResearch(props.client, id, type, taskData)
      .then(research => props.onResearch(research.id))
      .catch(error => {
        setSubmitting(false);
        setError(error);
      });
  };

  return formFactory({
    type,
    title,
    placeholder,

    error,
    submitting,
    onSubmit: handleSubmit,
  });
}


function formFactory({type, ...props}: TFactoryOptions): JSX.Element | null {
  if (type === ResearchType.question || type === ResearchType.heatmap) {
    return <NewCommonResearchForm type={type} {...props} />;
  }

  if (type === ResearchType.sideBySide) {
    return <NewSideBySideResearchForm type={type} {...props} />;
  }

  return null;
}

type TFactoryOptions = {
  type: TResearchType;
  title: string;
  placeholder: string;

  error: Error | null;
  submitting: boolean;
  onSubmit: (taskData: TCommonResearchInput | TSideBySideResearchInput) => void;
}

type TNewResearchControllerProps = {
  client: IAPIClient;
  template: ITemplate;

  onResearch: (id: string) => void;
}


function createResearch(client: IAPIClient, templateId: number, type: TResearchType, data: any): Promise<TPublicResearch> {
  if (type === ResearchType.question || type === ResearchType.heatmap) {
    return createCommonResearch(client, templateId, data);
  }

  if (type === ResearchType.sideBySide) {
    return createSideBySideResearch(client, templateId, data);
  }

  return Promise.reject(new UserFriendlyError("Unsupported research type"));
}


function createCommonResearch(client: IAPIClient, templateId: number, data: TCommonResearchInput): Promise<TPublicResearch> {
  const taskData: ICommonTask = {
    question: data.question,
    image: null,
  };

  const image = data.image || undefined;
  if (image) {
    taskData.image = Object.assign({}, image[1]);
  }

  const uploadImageIfNeeded = () =>
    image && client.misc.uploadImage(image[0]);

  const handleUploadResult = (meta?: IImageMeta) => {
    if (meta && taskData.image) {
      taskData.image.url = meta.url;
    }
  };

  const createResearch = () =>
    client.research.create(templateId, taskData);

  return Promise.resolve()
    .then(uploadImageIfNeeded)
    .then(handleUploadResult)
    .then(createResearch);
}


function createSideBySideResearch(client: IAPIClient, templateId: number, data: TSideBySideResearchInput): Promise<TPublicResearch> {
  const taskData: ISideBySideTaskData = {
    question: data.question,
    a: Object.assign({}, data.a[1]),
    b: Object.assign({}, data.b[1]),
  };

  const uploadImages = () =>
    Promise.all([
      client.misc.uploadImage(data.a[0]),
      client.misc.uploadImage(data.b[0]),
    ])

  const handleUploadResult = (meta: IImageMeta[]) => {
    const [a, b] = meta;
    taskData.a.url = a.url;
    taskData.b.url = b.url;
  };

  const createResearch = () =>
    client.research.create(templateId, taskData);

  return Promise.resolve()
    .then(uploadImages)
    .then(handleUploadResult)
    .then(createResearch);
}