import React from "react";

import { cn } from "../../lib"
import { Title } from "../Title";
import Icon from "../Icon";

import "./Accordion.scss";


const b = cn("accordion");

export const Accordion: React.FunctionComponent<TAccordionProps> = (props) => {
  const [isOpened, setOpened] = React.useState<boolean>(false);

  const handleToggle = () => {
    setOpened(!isOpened);
  };

  return (
    <div className={b({ opened: isOpened })}>
      <div className={b("head")} onClick={handleToggle}>
        <Title size="small" className={b("title")}>{props.title}</Title>
        <Icon.Arrow fill="#fc0" />
      </div>
      <div className={b("body")}>
        { props.children }
      </div>
    </div>
  );
}


type TAccordionProps = {
  title: string;
}