import React from "react";
import { useLocation } from "react-router-dom";

import { cn } from "../../lib"
import { useIsAuthenticated } from "../AuthProvider";
import { Link } from "../Link";
import { Logo } from "../Logo";
import { Menu } from "../Menu";
import { SandwichMenu } from "../SandwichMenu";

import "./Header.scss";


const b = cn("header");

export const Header: React.FunctionComponent<THeaderProps> = (props) => {
  const isAuthenticated = useIsAuthenticated();

  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <div className={b({}, [props.className])}>
      {props.title &&
        <SandwichMenu title={ props.title }>
          <SandwichMenu.Item>
            <Link to="/">New Task</Link>
          </SandwichMenu.Item>
          <SandwichMenu.Item>
            <Link to="/researches">History</Link>
          </SandwichMenu.Item>
          <SandwichMenu.Item>
            <Link to="/profile">Profile</Link>
          </SandwichMenu.Item>
        </SandwichMenu>
      }

      <Logo url={isHome ? "" : "/"}>
        Ask the Crowd
      </Logo>

      {isAuthenticated &&
        <Menu className={b("menu")} layout="auto">
          {isHome || (
            <Menu.Item>
              <Link to="/">New</Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <Link to="/researches">History</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/profile">Profile</Link>
          </Menu.Item>
        </Menu>
      }
    </div>
  );
}


type THeaderProps = {
  title?: string;
  className?: string;
}