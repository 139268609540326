import {Title} from "../../Title";

import {b} from "../AuthForm";


export const Head: React.FunctionComponent = () =>
  <div className={b("head")}>
    <div className={b("content")}>
      <Title size="large">
        Start testing your design
      </Title>

      <p>
        Ask users and quickly choose the best option. The test is&nbsp;usually about 5&ndash;10 minutes.
      </p>
    </div>
  </div>