import { cn } from "../../lib";
import { Link } from "../Link";
import { Menu } from "../Menu";

import "./Footer.scss";


const b = cn("footer");

export const Footer: React.FunctionComponent<TFooterProps> = (props) =>
  <div className={b({}, [props.className])}>
    <Menu layout="auto">
      <Menu.Item>
        <Link to="https://www.facebook.com/askthecrowdapp" newTab>
          Facebook
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/examples/icon-recognition">
          Blog
        </Link>
      </Menu.Item>
    </Menu>

    <Menu layout="auto" className={b("misc")}>
      <Menu.Item>
        <Link to="https://yandex.com/legal/confidential/" newTab>
          Privacy&nbsp;Policy
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/terms" newTab>
          Terms&nbsp;of&nbsp;use
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="mailto:hello@ask-the-crowd.app">
          Support
        </Link>
      </Menu.Item>
    </Menu>
  </div>


type TFooterProps = {
  className?: string;
}