import React from "react";
import ReactDOM from "react-dom";

import { cn } from "../../lib";

import "./Fade.scss";


const b = cn("fade");
const root = document.querySelector("#fade");

export class Fade extends React.Component<TFadeProps> {
  private readonly el: HTMLElement;

  constructor(props: TFadeProps) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    root && root.appendChild(this.el);
  }

  componentWillUnmount() {
    root && root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <div className={b({ visible: this.props.if })} onClick={this.props.onClick}>
        { this.props.children }
      </div>,
      this.el
    );
  }
}


type TFadeProps = {
  if: boolean;
  onClick?: VoidFunction;
}