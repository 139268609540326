import { b } from "../Research";


export type TRowProps = {
  wide?: boolean;
  centered?: boolean;
  className?: string;
}

export const Row: React.FunctionComponent<TRowProps> = ({ wide, centered, className, children }) =>
  <div className={b("row", { wide, centered }, [className])}>
    { children }
  </div>;