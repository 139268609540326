import React from "react";

import { cn } from "../../lib/core/cn";
import { Rect, Point } from "../../lib/geom";
import { useFrame } from "../AspectRatioArea/AspectRatioArea";

import { TClickableAreaProps, TClickableAreaClickProps } from "./ClickableArea.types";
import "./ClickableArea.scss";


const b = cn("clickable-area");

export const ClickableArea: React.FunctionComponent<TClickableAreaProps> = (props) => {
  const frame = new Rect(useFrame());
  if (frame.isEmpty()) {
    return null;
  }

  const points = props.points.map(p0 => {
    const p1 = new Point(p0).absoluteTo(frame.bounds);
    return { left: p1.x, top: p1.y };
  });

  const handleClick = function(event: React.MouseEvent) {
    props.onClick && props.onClick(Point.fromEvent(event).relativeTo(frame));
  };

  return (
    <div className={b({}, [props.className])} onClick={handleClick}>
      {points.map((point, index) =>
        <Click key={index} left={point.left} top={point.top} />
      )}
    </div>
  );
}

const Click: React.FunctionComponent<TClickableAreaClickProps> = ({ left, top }) =>
  <i className={b("click")} style={{ left, top }} />