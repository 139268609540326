import { AspectRatioArea } from "../../AspectRatioArea";
import { Button } from "../../Button";
import { ClickableArea } from "../../ClickableArea";
import { Heatmap, THeatmapPoint } from "../../Heatmap";
import { IPoint, IRect, Rect } from "../../../lib/geom";
import { makePointsToSelectionsMapping } from "../../Research/types/Heatmap";
import { SelectionArea } from "../../SelectionArea";
import { SelectionItem } from "../../SelectionItem";
import { THeatmapModerationResearch, THeatmapResearchResponse } from "../../../lib/types";

import { b, Moderation, TModerationProps, TModerationState } from "../Moderation";
import { Row } from "../misc";


type THeatmapProps = TModerationProps & {
  research: THeatmapModerationResearch;
}

type THeatmapState = TModerationState & {
  history: number[][];
  selections: TSelection[];
  alreadyModeratedResponses: IPoint[];
};

export type TSelection = {
  id: string;
  frame: IRect;
}

export class ModerationHeatmap extends Moderation<THeatmapProps, THeatmapState> {
  private readonly points: THeatmapResearchResponse[];
  private readonly alreadyModeratedResponses: IPoint[];

  constructor(props: THeatmapProps) {
    super(props);

    const {responses} = props.research;

    if (this.props.research.state !== "completed") {
      responses.forEach(response => {
        response.approved = undefined;
      });
    }

    this.state = Object.assign({}, this.state, {
      history: [],
      selections: [],
    });

    this.points = responses.filter(r => r.approved !== false);
    this.alreadyModeratedResponses = responses.filter(p => p.approved === false).map(p => p.data);

    this.onAddSelection = this.onAddSelection.bind(this);
    this.onCleanSelection = this.onCleanSelection.bind(this);
    this.onCloseSelection = this.onCloseSelection.bind(this);

    this.onUndoClick = this.onUndoClick.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  get pointsDeleted(): Set<number> {
    return new Set(this.state.history.reduce((a, c) => a.concat(c), []));
  }

  get pointsVisible(): THeatmapResearchResponse[] {
    return this.points
      .filter(point => !this.pointsDeleted.has(point.id));
  }

  get heatmapData(): THeatmapPoint[] {
    return this.pointsVisible.map(p => [p.data.x, p.data.y, 1]);
  }

  get percentOfVisiblePoints() {
    const points = this.pointsVisible.map(p => p.data);
    return makePointsToSelectionsMapping(points, this.state.selections);
  }

  get hasChanges(): boolean {
    return this.state.history.length > 0;
  }

  get canUseHistory(): boolean {
    return !this.props.isSubmitting && this.hasChanges;
  }

  removeSelection(id: string) {
    this.setState({
      selections: this.state.selections.filter(selection => {
        return selection.id !== id;
      }),
    });
  }

  onAddSelection(frame: IRect) {
    const id = Math.random().toString(16);

    this.setState({
      selections: this.state.selections.concat({
        id,
        frame,
      }),
    });
  }

  onCleanSelection(id: string) {
    const selection = this.state.selections
      .filter(s => s.id === id)
      .pop();

    const frame = new Rect(selection?.frame);

    const pointsToRemove: number[] = [];
    this.pointsVisible.forEach(point =>
      frame.contains(point.data) && pointsToRemove.push(point.id));

    this.setState({
      history: this.state.history.concat([pointsToRemove]),
    });

    this.removeSelection(id);
  }

  onCloseSelection(id: string) {
    this.removeSelection(id);
  }

  onUndoClick() {
    this.setState({
      history: this.state.history.slice(0, -1),
    });
  }

  onResetClick() {
    this.setState({
      history: [],
    });
  }

  onSubmitClick() {
    this.props.onSubmit(Array.from(this.pointsDeleted));
  }

  renderBody() {
    const {image} = this.props.research.task;

    return (
      <Row>
        <AspectRatioArea width={image.width} height={image.height} scale={image.scale}>
          <Heatmap max={5} image={image.url} points={this.heatmapData} />

          <ClickableArea
            className={b("deleted")}
            points={this.alreadyModeratedResponses}
          />

          <SelectionArea onSelection={this.onAddSelection}>
            {this.state.selections.map(selection =>
              <SelectionItem
                key={selection.id}
                frame={selection.frame}
                deletable={true}
                value={this.percentOfVisiblePoints[selection.id]}
                onClose={() => this.onCloseSelection(selection.id)}
                onDelete={() => this.onCleanSelection(selection.id)}
              />
            )}
          </SelectionArea>
        </AspectRatioArea>
      </Row>
    );
  }

  renderFoot(): JSX.Element {
    return (
      <Row centered className={b("foot")}>
        <Button type="button" disabled={!this.canUseHistory} onClick={this.onUndoClick}>Undo</Button>
        <Button type="reset" disabled={!this.canUseHistory} onClick={this.onResetClick}>Reset</Button>
        <Button type="submit" disabled={!this.canSubmit} onClick={this.onSubmitClick}>Submit</Button>
      </Row>
    );
  }
}