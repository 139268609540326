import { Page } from "../../components/Page/Page";
import { Title } from "../../components/Title";

import "./NotFoundPage.scss";


export const NotFoundPage: React.FunctionComponent = (props) =>
  <Page>
    <div className="page__content not-found backface">
      <Title size="large">
        Not Found
      </Title>

      <p>Sorry, we can't find the page you're looking for.</p>
    </div>
  </Page>;