import { AskError, InputValidationError, InternalError, UniquinessError } from "../core/errors";

import { IResponse, TError } from ".";


export class ServerError extends AskError {}
export class NetworkError extends InternalError {}
export class UnknownError extends ServerError {}

export class HTTPError extends ServerError {
  public readonly response: IResponse;

  constructor(response: IResponse, message: string = "") {
    super(message || `Server returns code ${response.status}`);
    this.response = response;
  }

  get data(): Record<string, any> {
    return {
      status: this.response.status,
      data: this.response.body && this.response.body.slice(0, 1024),
    };
  }
}

export class UnauthorizedError extends HTTPError {}
export class ForbiddenError extends HTTPError {}
export class NotFoundError extends HTTPError {
  userFriendly = true;

  title = "Not found"
  message = "The resource requested could not be found"
}

export class InternalServerError extends HTTPError {}

export class ResponseParseError extends HTTPError {
  constructor(response: IResponse) {
    super(response, "Could't parse server response");
  }
}

export class UnexpectedResponseFormat extends ServerError {}

export function createErrorFromResponse(error: TError, response: IResponse): AskError {
  if (error.name === "InputValidationError") {
    return new InputValidationError(error.message);
  }

  if (error.name === "UniquinessError") {
    return new UniquinessError(error.message);
  }

  if (error.name === "NotFoundError") {
    return new NotFoundError(response, error.message);
  }

  return new UnknownError(error.message);
}