import { Content } from "../../components/Content/Content";
import { Page } from "../../components/Page/Page";
import { ResearchList } from "../../components/ResearchList/ResearchList";


export const HistoryPage: React.FunctionComponent = (props) =>
  <Page auth name="history" title="History">
    <Content nogap className="page__content backface">
      <ResearchList />
    </Content>
  </Page>