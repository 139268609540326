import { Fragment } from "react";
import { TImage } from "../../../lib/types";
import { ImageUploader } from "../../ImageUploader/ImageUploader";
import { Input } from "../../Input";

import { b, NewResearchForm, TNewResearchFormProps, TNewResearchFormState } from "../NewResearchForm";


export class NewSideBySideResearchForm extends NewResearchForm<TNewSideBySideResearchFormProps, TNewSideBySideResearchFormState> {
  constructor(props: TNewSideBySideResearchFormProps) {
    super(props);

    this.state = {
      question: "",
      a: null,
      b: null,

      shaking: {
        question: false,
        a: false,
        b: false,
      },
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  get shouldQuestionShake(): boolean {
    return this.state.shaking.question;
  }

  validate(): boolean {
    const isQuestionValid = this.isQuestionValid;
    const isAValid = Boolean(this.state.a);
    const isBValid = Boolean(this.state.b);

    this.setState({
      shaking: {
        question: !isQuestionValid,
        a: !isAValid,
        b: !isBValid,
      },
    });

    return isQuestionValid && isAValid && isBValid;
  }

  onSubmit() {
    const { question, a, b } = this.state;

    if (!a || !b) {
      return;
    }

    this.validate() && this.props.onSubmit({
      question, a, b
    });
  }

  setShaking(name: keyof TShakingAnimation, value: boolean) {
    this.setState({
      shaking: Object.assign(this.state.shaking, {
        [name]: value,
      }),
    });
  }

  onFileChange(side: "a" | "b", file: File, meta: TImage) {
    if (side === "a") {
      this.setState({
        a: [file, meta],
      });
    }

    if (side === "b") {
      this.setState({
        b: [file, meta],
      });
    }
  }

  renderForm(): JSX.Element {
    const stopShaking = (name: keyof TShakingAnimation) =>
      () => this.setShaking(name, false);

    const { shaking } = this.state;

    const onFileChange = (side: "a" | "b") =>
      (file: File, meta: TImage) => this.onFileChange(side, file, meta);

    return (
      <Fragment>
        <Input
          shake={shaking.question}
          placeholder={this.props.placeholder}
          onChange={this.onQuestionChange}
          onAnimationEnd={stopShaking("question")}
        />

        <div className={b("grid")}>
          <div className={b("area", { shake: shaking.a }, ["row"])} onAnimationEnd={stopShaking("a")}>
            <ImageUploader onFile={onFileChange("a")} label="A" />
          </div>

          <div className={b("area", { shake: shaking.b }, ["row"])} onAnimationEnd={stopShaking("b")}>
            <ImageUploader onFile={onFileChange("b")} label="B" />
          </div>
        </div>
      </Fragment>
    )
  }
}


type TNewSideBySideResearchFormProps = TNewResearchFormProps & {
  type: "side-by-side",

  onSubmit: (task: TSideBySideResearchInput) => void;
}

export type TSideBySideResearchInput = {
  question: string;
  a: [File, TImage];
  b: [File, TImage];
}

type TNewSideBySideResearchFormState = TNewResearchFormState & {
  question: string;
  a: [File, TImage] | null,
  b: [File, TImage] | null,
  shaking: TShakingAnimation;
}

type TShakingAnimation = {
  question: boolean;
  a: boolean;
  b: boolean;
}